import React, { FC, useState } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import LVarPositionLevel from './subComponents/LVarPositionLevel.component';
import LVarFundSummary from './subComponents/LVarFundSummary.component';

const PeLiquiidtyValueAtRisk: FC<FundInfoComponentProps> = ({
  fundId,
  positionDate,
}) => {
  const [gmid, setGmid] = useState<string>('');

  const varData = useFetchData({
    url: `get_private_equity_current_liquidity_value_at_risk/${fundId}/${positionDate}`,
    keyName: `pe_liquidity_var_data_${fundId}_${positionDate}`,
    makeFetch: true,
  });

  const timeSeriesData = useFetchData({
    url: `get_private_equity_liquidity_value_at_risk/${fundId}/${positionDate}/${gmid}`,
    keyName: `pe_liquidity_var_timeseries_${fundId}_${positionDate}_${gmid}`,
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[varData]}
      customLoadingMessages={[
        'Loading Private Equity Liquidity Quantile Risk Data',
      ]}
    >
      <LVarFundSummary varData={varData} timeSeriesData={timeSeriesData} />
      <LVarPositionLevel data={varData} />
    </GeneralComponentErrorShield>
  );
};

export default PeLiquiidtyValueAtRisk;
