import Cookies from 'js-cookie';
import {
  AUTHENTICATE,
  AUTHENTICATE_WITH_2FA,
  AUTHENTICATION_FAILED,
  AUTHENTICATION_IN_PROGRESS,
  AUTHENTICATION_WITH_2FA_IN_PROGRESS,
  AuthErrorObject,
  AuthenticationAction,
  CANCEL_TWO_FACTOR_AUTHENTICATION_IN_PROGRESS,
  IAuthenticate,
  IAuthenticateWith2FA,
  IAuthenticationInProgress,
  IAuthenticationWith2FAInProgress,
  ICancel2FAInProgress,
  IUnauthenticate,
  LoginCredentials,
  LoginCredentials2FAWithTokens,
  TWO_FACTOR_AUTHENTICATION_FAILED,
  UNAUTHENTICATE,
  VerficationDetails,
} from '../../types/redux/auth/authTypes';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import requestClient from '../../utilities/requestClient';
import jwt_decode from 'jwt-decode';
import FastAPIAuthService, {
  FastApiFetchError,
} from '../../services/risksystem3-public-auth-service';
import TokenService from '../../services/token-service';
import { formSafeAppend } from '../../utilities/helpers/forms/formUtils';
import { AxiosError } from 'axios';

// ------------------ Common Code ------------------ //

const handleLoginSuccess = async (
  dispatch: Dispatch<AuthenticationAction, {}, any>,
  response: any,
  loginResponse: any,
) => {
  window.localStorage.setItem('authenticated', 'true');

  const regex = /^https:\/\/dev2\w*\.risksystem\.com.*/;

  if (process.env.NODE_ENV === 'development') {
    Cookies.set('user_id', 'development');
  } else if (regex.test(window.location.href)) {
    const domain = new URL(window.location.href).hostname;
    Cookies.set('dev_user_id', 'development', {
      domain,
      path: '/',
      secure: true,
      sameSite: 'None',
      expires: 30,
    });
  }

  TokenService.setAccessToken(loginResponse.data.access_token);

  dispatch(
    authenticate(
      response.data.user_name,
      loginResponse.data.client_name,
      loginResponse.data.config_name,
    ),
  );
};

// ------------------ General Authentication Actions ------------------ //

export function unauthenticate(): IUnauthenticate {
  return {
    type: UNAUTHENTICATE,
  };
}

export function authenticate(
  user_id: string,
  client_name: string,
  config_name: string,
): IAuthenticate {
  return {
    type: AUTHENTICATE,
    user_id: user_id,
    client_name: client_name,
    config_name: config_name,
  };
}

export function authenticationInProgress(): IAuthenticationInProgress {
  return {
    type: AUTHENTICATION_IN_PROGRESS,
  };
}

export function authenticationFailed(errorMessage: AuthErrorObject) {
  return {
    type: AUTHENTICATION_FAILED,
    payload: errorMessage,
  };
}

export function logOut() {
  return async (dispatch: Dispatch<AuthenticationAction, {}, any>) => {
    FastAPIAuthService.FastAPILogout();
    Cookies.remove('user_id');
    localStorage.clear();
    dispatch({ type: 'USER_LOGOUT' });
    const client = requestClient();
    await client.get('/logout');
  };
}

export function logIn(credentials: LoginCredentials) {
  return async (dispatch: Dispatch<AuthenticationAction, {}, any>) => {
    const client = requestClient();

    // Set Authentication In Progress
    dispatch(authenticationInProgress());

    try {
      // Form Data for both login forms
      const formData = new FormData();
      formData.append('username', credentials.username);
      formData.append('password', credentials.password);
      formData.append('grant_type', 'password');

      // Run both requests concurrently - speedy gonzales
      const [oldAuthResponse, fastAPILoginResponse] = await Promise.all([
        // Post to the OG login endpoint
        client.post('/api_token', formData, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Basic Og==',
          },
        }),
        // Post to the FastAPI login endpoint
        FastAPIAuthService.FastAPIlogin(formData),
      ]);

      // Decode token from OG login response
      const decodedToken: any = jwt_decode(oldAuthResponse.data.access_token);

      switch (oldAuthResponse.data.login_return_code) {
        case 0: // Successful login
          const res = await FastAPIAuthService.fetchFastAPIUserInfo(
            fastAPILoginResponse.tokenRes,
            fastAPILoginResponse.decryptedToken,
          );

          handleLoginSuccess(dispatch, res, oldAuthResponse);
          break;

        case 3: // 2FA required
          handleTwoFactorAuthentication(
            dispatch,
            credentials.username,
            oldAuthResponse,
            decodedToken,
          );
          break;

        default: // Login failed
          dispatch(unauthenticate());
      }
    } catch (error) {
      if (error instanceof FastApiFetchError) {
        const newError = error as FastApiFetchError;
        const errorDisplay = newError.responseData || {
          detail: newError.message,
        };
        FastAPIAuthService.FastAPILogout();
        Cookies.remove('user_id');

        return dispatch(authenticationFailed(errorDisplay));
      }
      handleLoginErrorResponse(dispatch, error);
    }
  };
}

// Helper function to handle 2FA scenarios
function handleTwoFactorAuthentication(
  dispatch: Dispatch<AuthenticationAction, {}, any>,
  username: string,
  loginResponse: any,
  decodedToken: any,
) {
  const { access_token: accessToken, auth_key: authKey } = loginResponse.data;

  // Case where 2FA is going to be required.
  // Check what kind of authentication Is required.
  if (decodedToken.is_email_needed) {
    dispatch(
      requestVerficationCodeEmail({
        username,
        accessToken,
        authKey,
        isQrCodeRequired: decodedToken.is_qr_code_needed || false,
        isEmailTokenRequired: decodedToken.is_email_needed,
      }),
    );
  } else if (decodedToken.is_qr_code_needed) {
    // In this case skip straight to the login screen.
    dispatch(
      authenticateWith2FA(
        username,
        loginResponse.data.client_name,
        loginResponse.data.config_name,
        accessToken,
        authKey,
        false,
        decodedToken.is_email_needed,
        decodedToken.is_qr_code_needed,
      ),
    );
  } else {
    dispatch(
      authenticationFailed({ detail: 'There was an error logging in.' }),
    );
  }
}

// Helper function to handle login errors
function handleLoginErrorResponse(
  dispatch: Dispatch<AuthenticationAction, {}, any>,
  error: any,
) {
  const errorStatus = error.response ? error.response.status : null;
  const errorData = error.response ? error.response.data : null;

  if (errorStatus === 401 || errorStatus === 403) {
    dispatch(authenticationFailed(errorData));
  } else {
    dispatch(
      authenticationFailed({ detail: 'There was an error logging in.' }),
    );
  }
}

// ------------------ 2FA Authentication Actions ------------------ //

export function authenticationWith2FAInProgress(
  emailTokenRequired: boolean,
  qrCodeRequired: boolean,
  user_id: string,
  client_name: string,
  config_name: string,
  accessToken: string,
  authKey: string,
): IAuthenticationWith2FAInProgress {
  return {
    type: AUTHENTICATION_WITH_2FA_IN_PROGRESS,
    qrCodeRequired: qrCodeRequired,
    emailTokenRequired: emailTokenRequired,
    user_id: user_id,
    client_name: client_name,
    config_name: config_name,
    accessToken: accessToken,
    authKey: authKey,
  };
}

export function cancel2FALogin(): ICancel2FAInProgress {
  return {
    type: CANCEL_TWO_FACTOR_AUTHENTICATION_IN_PROGRESS,
  };
}

export function authenticateWith2FA(
  user_id: string,
  client_name: string,
  config_name: string,
  accessToken: string,
  authKey: string,
  emailSent: boolean,
  emailTokenRequired: boolean,
  qrCodeRequired: boolean,
): IAuthenticateWith2FA {
  return {
    type: AUTHENTICATE_WITH_2FA,
    user_id: user_id,
    client_name: client_name,
    config_name: config_name,
    accessToken: accessToken,
    authKey: authKey,
    qrCodeRequired: qrCodeRequired,
    emailTokenRequired: emailTokenRequired,
    emailSent: emailSent,
  };
}

export function twoFactorAuthenticationFailed(
  errorMessage: AuthErrorObject,
  user_id: string,
  accessToken: string,
  authKey: string,
  emailSent: boolean,
  emailTokenRequired: boolean,
  qrCodeRequired: boolean,
) {
  return {
    type: TWO_FACTOR_AUTHENTICATION_FAILED,
    errorMessage: errorMessage,
    user_id: user_id,
    accessToken: accessToken,
    authKey: authKey,
    emailSent: emailSent,
    emailTokenRequired: emailTokenRequired,
    qrCodeRequired: qrCodeRequired,
  };
}

export function requestVerficationCodeEmail(
  verficiationDetails: VerficationDetails,
) {
  return async (dispatch: Dispatch<AuthenticationAction, {}, any>) => {
    const client = requestClient();

    // Set authentication in progress.
    dispatch(
      authenticationWith2FAInProgress(
        verficiationDetails.isEmailTokenRequired,
        verficiationDetails.isQrCodeRequired,
        verficiationDetails.username,
        '',
        '',
        verficiationDetails.accessToken,
        verficiationDetails.authKey,
      ),
    );

    // Create an object of formData
    const formData = new FormData();
    formData.append('auth_key', verficiationDetails.authKey);

    client
      .post('/api/v1/get_verification_code?auth_type=user_email', formData, {
        headers: {
          Accept: 'application/json',
          'content-type': 'multipart/form-data',
          Authorization: `Bearer ${verficiationDetails.accessToken}`,
        },
      })
      .then((qr_response) => {
        if (qr_response.data.status === 200) {
          dispatch(
            authenticateWith2FA(
              verficiationDetails.username,
              '',
              '',
              verficiationDetails.accessToken,
              verficiationDetails.authKey,
              true,
              verficiationDetails.isEmailTokenRequired,
              verficiationDetails.isQrCodeRequired,
            ),
          );
        }
      })
      .catch((err) => {
        dispatch(
          twoFactorAuthenticationFailed(
            {
              detail:
                'An error occured while attempting to send the verification code to your email.',
            },
            verficiationDetails.username,
            verficiationDetails.accessToken,
            verficiationDetails.authKey,
            false,
            verficiationDetails.isEmailTokenRequired,
            verficiationDetails.isQrCodeRequired,
          ),
        );
      });
  };
}

// Function to allow the user to have the QR code resent to their email.
export function requestQRCodeEmail(verficiationDetails: VerficationDetails) {
  return async (dispatch: Dispatch<AuthenticationAction, {}, any>) => {
    const client = requestClient();

    // Set authentication in progress.
    dispatch(
      authenticationWith2FAInProgress(
        verficiationDetails.isEmailTokenRequired,
        verficiationDetails.isQrCodeRequired,
        verficiationDetails.username,
        '',
        '',
        verficiationDetails.accessToken,
        verficiationDetails.authKey,
      ),
    );

    client
      .post(
        '/api/v1/require_qr_code',
        {
          auth_key: verficiationDetails.authKey,
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${verficiationDetails.accessToken}`,
          },
        },
      )
      .then((qr_response) => {
        if (qr_response.data.status === 200) {
          dispatch(
            authenticateWith2FA(
              verficiationDetails.username,
              '',
              '',
              verficiationDetails.accessToken,
              verficiationDetails.authKey,
              true, // Indicate that the QR code was sent.
              verficiationDetails.isEmailTokenRequired,
              verficiationDetails.isQrCodeRequired,
            ),
          );
        }
      })
      .catch((err) => {
        dispatch(
          twoFactorAuthenticationFailed(
            {
              detail: 'An error occured while attempting to send QR code.',
            },
            verficiationDetails.username,
            verficiationDetails.accessToken,
            verficiationDetails.authKey,
            false,
            verficiationDetails.isEmailTokenRequired,
            verficiationDetails.isQrCodeRequired,
          ),
        );
      });
  };
}

export function logInWith2FA(credentials: LoginCredentials2FAWithTokens) {
  return async (dispatch: Dispatch<AuthenticationAction, {}, any>) => {
    const client = requestClient();

    dispatch(
      authenticationWith2FAInProgress(
        credentials.emailCode !== '',
        credentials.qrCode !== '',
        credentials.username,
        '',
        '',
        credentials.accessToken || '',
        credentials.authKey || '',
      ),
    );

    try {
      // Prepare the form data for both posts
      const formData = new FormData();
      formData.append('auth_key', credentials.authKey || '');
      formSafeAppend(
        formData,
        'email_verification_code',
        credentials.emailCode,
      );
      formSafeAppend(formData, 'qr_code_verification_code', credentials.qrCode);

      // Run both requests concurrently
      const [loginResponse, fastAPILoginResponse] = await Promise.all([
        // Post to the OG login endpoint
        client.post('/api_verification_code', formData, {
          headers: {
            Accept: 'application/json',
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${credentials.accessToken}`,
          },
        }),

        // Post to the FastAPI login2fa endpoint
        FastAPIAuthService.FastAPIlogin2fa(
          credentials.accessToken || '',
          formData,
        ),
      ]);

      // Check the login return code from the OG login endpoint
      if (loginResponse.data.login_return_code === 0) {
        // Successful login
        handleLoginSuccess(dispatch, fastAPILoginResponse, loginResponse);
      } else {
        // Login failed
        dispatch(unauthenticate());
      }
    } catch (error) {
      const axiosError = error as AxiosError; // We can cast error as AxiosError as client is Axios

      const errorResponse = axiosError.response?.data || {
        detail: 'There was an error logging in.',
      };

      dispatch(
        twoFactorAuthenticationFailed(
          errorResponse,
          credentials.username,
          credentials.accessToken || '',
          credentials.authKey || '',
          false,
          credentials.isEmailTokenRequired,
          credentials.isQrCodeRequired,
        ),
      );
    }
  };
}
