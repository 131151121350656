import { AxiosError } from 'axios';
import FastAPIInstance, { updateAxiosHeaders } from './risksystem3-public-api';
import TokenService from './token-service';
import jwt_decode from 'jwt-decode';

interface DecryptedToken {
  exp: number;
  has_multi_clients: boolean;
  has_specific_funds: boolean;
  has_specific_resources: boolean;
  ip_address: string;
  is_email_needed: boolean;
  is_qr_code_needed: boolean;
  two_factor_auth_is_valid: boolean;
  user_client_id: string;
  user_email: string;
  user_id: string;
  user_type: string;
}
interface FastAPIErrorResponse {
  detail: string;
  status_code?: number;
  error?: string;
}

export class FastApiFetchError extends Error {
  public responseData: FastAPIErrorResponse | null;

  constructor(
    message: string,
    responseData: FastAPIErrorResponse | null,
    name?: string,
  ) {
    super(message);
    this.responseData = responseData;
    this.name = name || 'FastApiFetchError';
  }
}

const FastAPIlogin = async (formData: FormData) => {
  let tokenRes: any;
  let decryptedToken: DecryptedToken;

  // First we need to get the token and decode it
  try {
    tokenRes = await FastAPIInstance.post(`/v1/token`, formData);

    decryptedToken = jwt_decode(tokenRes.data.access_token) as DecryptedToken;

    TokenService.setAccessToken(tokenRes.data.access_token);
    // Unsure why this is needed, but seems to directly set the token faster
    updateAxiosHeaders(FastAPIInstance, tokenRes.data.access_token);
  } catch (error) {
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 401) {
      throw new FastApiFetchError(
        'Invalid credentials',
        axiosError.response?.data,
        'token',
      );
    }
    if (axiosError.response?.status === 403) {
      throw new FastApiFetchError(
        'Error fetching token or decoding',
        axiosError.response?.data,
        'token',
      );
    }
    throw new FastApiFetchError(
      'Error fetching token or decoding',
      null,
      'token',
    );
  }

  return { tokenRes, decryptedToken };
};

export async function fetchFastAPIUserInfo(
  tokenRes: any,
  decryptedToken: any,
): Promise<any> {
  try {
    const response = await FastAPIInstance.get(
      `/v1/user/user_id/${decryptedToken.user_id}`,
      {
        headers: {
          Authorization: `Bearer ${tokenRes.data.access_token}`,
        },
      },
    );

    if (response.status === 200) {
      TokenService.setUser({ ...tokenRes.data, ...response.data });
    }

    return response;
  } catch (error) {
    throw new FastApiFetchError('Failed to fetch user data', null, 'user');
  }
}

const FastAPIlogin2fa = async (accessToken: string, formData: FormData) => {
  let tokenRes: any;
  let decryptedToken: DecryptedToken;

  // First we need to get the token and decode it
  try {
    tokenRes = await FastAPIInstance.post('/v1/verification_code', formData, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    decryptedToken = jwt_decode(tokenRes.data.access_token) as DecryptedToken;
  } catch (error) {
    throw new FastApiFetchError(
      'Error fetching token or decoding',
      null,
      'token',
    );
  }

  // Next we need to fetch the user info
  return await fetchFastAPIUserInfo(tokenRes, decryptedToken);
};

const FastAPILogout = () => {
  TokenService.removeUser();
  // redirect user to login page
  // unless the user is on signup or reset-password pages
  if (
    !window.location.pathname.includes('/signup') &&
    !window.location.pathname.includes('/reset-password') &&
    !window.location.pathname.includes('/login')
  ) {
    window.location.assign('/raptor/login');
  }
};

const getCurrentUser = () => {
  const userString = localStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : null;
  return user;
};

function getAuthHeader() {
  const userString = localStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : null;

  if (user && user.access_token) {
    return { Authorization: 'Bearer ' + user.access_token };
  } else {
    return {};
  }
}

const FastAPIAuthService = {
  // signup,
  FastAPIlogin,
  FastAPIlogin2fa,
  fetchFastAPIUserInfo,
  FastAPILogout,
  getCurrentUser,
  getAuthHeader,
};

export default FastAPIAuthService;
