import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { mainColors } from '../../../../../styling/theme';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  link: {
    color: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
    padding: '0.5rem 1rem',
    width: 'fit-content',
    borderRadius: '0.2rem',
    '&:hover': {
      backgroundColor: mainColors.hoverOverWhite,
    },
  },
}));

export interface LinkCellProps {
  cellText: string;
  linkString: string;
}

const LinkCell: React.FC<LinkCellProps> = ({ cellText, linkString }) => {
  const classes = useStyles();

  return (
    <NavLink to={linkString} className={classes.link}>
      {cellText}
    </NavLink>
  );
};

export default LinkCell;
