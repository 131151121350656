import { makeStyles } from '@mui/styles';
import { greys, RaptorTheme } from '../../../../../styling/theme';

// Define some styles for th page
export const useKidsOverviewStyles = makeStyles<RaptorTheme>((theme) => ({
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
  pickerRoot: {
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: theme.shadows[5],
    marginRight: '1.2rem',
    height: '5rem',
    width: '100%',
    marginBottom: '1.2rem',
  },
  selectRoot: {
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  generalSelectContainer: {
    width: '20%',
    marginLeft: '2rem',
    marginTop: '2rem',
  },
  downloadButtonContainer: {
    display: 'flex',
    // width: '20%',
    height: '3rem',
    marginTop: '2rem',
    marginRight: '2rem',
    cursor: 'pointer',
  },
}));
