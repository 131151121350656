import { useLocation } from 'react-router-dom';

const useRouteParams = (parameters: string | string[]) => {
  const search = useLocation().search;
  const objectContainingParams: { [key: string]: string | null } = {};
  if (Array.isArray(parameters)) {
    parameters.forEach((parameter) => {
      objectContainingParams[parameter] = new URLSearchParams(search).get(
        parameter,
      );
    });
  } else {
    objectContainingParams[parameters] = new URLSearchParams(search).get(
      parameters,
    );
  }

  return objectContainingParams;
};

export default useRouteParams;

export const useRouteParamsX2 = (parameters: string | string[]) => {
  const search = window.location.search;
  const objectContainingParams: { [key: string]: string | null } = {};
  if (Array.isArray(parameters)) {
    parameters.forEach((parameter) => {
      objectContainingParams[parameter] = new URLSearchParams(search).get(
        parameter,
      );
    });
  } else {
    objectContainingParams[parameters] = new URLSearchParams(search).get(
      parameters,
    );
  }

  return objectContainingParams;
};
