import { FC, useEffect, useState } from 'react';
import useRouteParams from '../../../../../hooks/useRouteParams';
import { useDispatch } from 'react-redux';
import {
  toggleControlBarShowing,
  updateControlBarComponents,
} from '../../../../../redux/ui/controlbar/actions';
import PriipsKidsEditorGridItem from '../layout/PriipsKidsEditorGridItem.component';
import Raptor2Loading from '../../../../feedback/Raptor2Loading';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import BackToOverviewBtn from '../../components/buttons/BackToOverviewBtn';
import { useRaidrKids } from '../context/RaidrKidsContext';
import { formatReviewContentFromResponse } from '../utils/review.utils';
import { useKIDSReviewData } from '../services/queries';
import { useGenerateNewReviewKIDPdf } from '../services/mutations';
import { useRaidrViewStyles } from '../styles/Raidr.styles';
import ErrorBoundaryMessage from '../../../../ui/ErrorBoundaryMessage';

const CompareKIDVersions: FC = () => {
  const classes = useRaidrViewStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { iframeTitle, backToOverviewLink } = useRaidrKids();

  const [reviewContentData, setReviewContentData] = useState<any[]>([]);

  // Get route params
  const fundId = useRouteParams('fundId')['fundId'];
  const isin = useRouteParams('isin')['isin'];
  const documentLanguage =
    useRouteParams('documentLanguage')['documentLanguage'];
  const targetVersion = useRouteParams('targetVersion')['targetVersion'];
  const comparisonVersion =
    useRouteParams('comparisonVersion')['comparisonVersion'];

  const { data, isPending, error } = useKIDSReviewData(
    fundId,
    isin,
    targetVersion,
    comparisonVersion,
    documentLanguage,
  );

  const createNewReviewKIDPdf = useGenerateNewReviewKIDPdf();

  useEffect(() => {
    if (data) {
      setReviewContentData(formatReviewContentFromResponse(data));
    }
  }, [data]);

  const getPDF = async () => {
    // Check if the required fields have been filled in.
    if (!reviewContentData || !reviewContentData.length) {
      return;
    }

    // Create an object of formData
    const formData = new FormData();
    // Convert the content to json
    const priipsKidsJson = JSON.stringify(reviewContentData);
    // Update the formData object.
    formData.append('contents', priipsKidsJson);
    formData.append('params', JSON.stringify(['contents']));

    // Create the request
    createNewReviewKIDPdf
      .mutateAsync(formData)
      .then((response: { data: BlobPart }) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const file = window.URL.createObjectURL(blob);
        const iframe = document.querySelector('iframe');
        if (iframe?.src) {
          iframe.src = file;
          iframe.title = iframeTitle;
        }
      });
  };

  useEffect(() => {
    if (reviewContentData.length) {
      getPDF();
    }
  }, [reviewContentData]);

  const backToOverview = () => {
    dispatch(toggleControlBarShowing(false));
    navigate(backToOverviewLink);
  };

  // Toggle the control bar to be shown
  useEffect(() => {
    if (reviewContentData.length > 0) {
      dispatch(
        updateControlBarComponents([
          <BackToOverviewBtn
            backToOverview={backToOverview}
            dontSetPosition
            key="back_to_overview"
          />,
          <div className={classes.fundNameContainer}>
            <Typography variant="h6">
              {`${reviewContentData[0].comparison[0].fund_name} - ${isin}`}
            </Typography>
          </div>,
        ]),
      );
      dispatch(toggleControlBarShowing(true));
    }
  }, [reviewContentData]);

  if (isPending) {
    return <Raptor2Loading centerWrap messages={[`Loading Raidr ...`]} />;
  }

  if (error) {
    return <ErrorBoundaryMessage />;
  }

  return (
    <PriipsKidsEditorGridItem xs={12}>
      <div className={classes.viewerContainer}>
        <div className={classes.iframeContainer}>
          <iframe
            src=""
            width={createNewReviewKIDPdf.isPending ? '0%' : '100%'}
            height="100%"
            title={'kid.pdf'}
          ></iframe>
          {createNewReviewKIDPdf.isPending ? (
            <Raptor2Loading centerWrap messages={['Generating Document...']} />
          ) : null}
        </div>
      </div>
    </PriipsKidsEditorGridItem>
  );
};

export default CompareKIDVersions;
