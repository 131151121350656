import { ColumnDef } from '@tanstack/react-table';
import { ColumnData } from '../../../../tables/ultraTable/types/column.types';
import { ifNumberRoundToNDecimalPlaces } from '../../../../../utilities/numberFormatters';

// 200px estimated padding and index - This is still hardcoded at 12 column minsize which is not ideal
const SIZE = (window.innerWidth - 200) / 8;
const MIN_SIZE = 100;

export interface SubscriptionRedemptionData {
  base_currency: string;
  date: string;
  fx_rate: number;
  pct_of_total_nav: number;
  share_class: string;
  type: 'SUBSCRIPTION' | 'REDEMPTION';
  value_in_base_currency: number;
  value_in_local_currency: number;
}

export const ALL_SUB_RED_COLS: ColumnData[] = [
  {
    id: 'date',
    title: 'Date',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: 300,
    minSize: MIN_SIZE,
  },
  {
    id: 'base_currency',
    title: 'Base Currency',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'fx_rate',
    title: 'FX Rate',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'share_class',
    title: 'Share Class',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },

  {
    id: 'type',
    title: 'Type',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'value_in_local_currency',
    title: 'Value in Share Class Currency',
    filterVariant: 'select',
    filterFn: 'arrIncludesSome',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'value_in_base_currency',
    title: 'Value in Base Currency',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
  {
    id: 'pct_of_total_nav',
    title: '% of Total NAV',
    filterVariant: 'range',
    size: SIZE,
    minSize: MIN_SIZE,
  },
];

export const buildColumns = (columns: ColumnData[]) => {
  const cols: ColumnDef<any>[] = [];

  columns.forEach((dc: ColumnData) => {
    cols.push({
      id: dc.id,
      accessorKey: dc.id,
      header: dc.title,
      footer: dc.id,
      ...(dc.enableResizing && { enableResizing: dc.enableResizing }),
      ...(dc.enableColumnFilter && {
        enableColumnFilter: dc.enableColumnFilter,
      }),

      minSize: dc.minSize,
      ...(dc.filterFn && { filterFn: dc.filterFn }),
      meta: {
        filterVariant: dc.filterVariant,
        short_title: dc.short_title ? dc.short_title : dc.title,
      },
      ...(dc.size && { size: dc.size }),
      cell: (props) => {
        if (dc.id === 'pct_of_total_nav') {
          return `${ifNumberRoundToNDecimalPlaces(props.getValue(), 2)}%`;
        } else {
          return props.getValue();
        }
      },
    });
  });

  return cols;
};

export const buildData = (data: SubscriptionRedemptionData[]) => {
  if (!data) {
    return [];
  }
  return data.map((row) => ({
    date: row.date,
    base_currency: row.base_currency,
    fx_rate: row.fx_rate,
    share_class: row.share_class,
    type: row.type,
    value_in_local_currency: row.value_in_local_currency,
    value_in_base_currency: row.value_in_base_currency,
    pct_of_total_nav: ifNumberRoundToNDecimalPlaces(row.pct_of_total_nav, 2),
  }));
};
