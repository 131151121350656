import React, { FC } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import ValueAtRiskFundSummary from './subComponents/ValueAtRiskFundSummary.component';
import ValueAtRiskPositionLevel from './subComponents/ValueAtRiskPositionLevel.component';

const PeValueAtRisk: FC<FundInfoComponentProps> = ({
  fundId,
  positionDate,
}) => {
  const varData = useFetchData({
    url: `get_private_equity_current_value_at_risk/${fundId}/${positionDate}`,
    keyName: `pe_var_data_${fundId}_${positionDate}`,
    makeFetch: positionDate && fundId ? true : false,
  });

  const timeSeriesData = useFetchData({
    url: `get_private_equity_value_at_risk/${fundId}/${positionDate}`,
    keyName: `pe_var_timeseries_${fundId}_${positionDate}`,
    makeFetch: positionDate && fundId ? true : false,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[varData, timeSeriesData]}
      customLoadingMessages={['Loading Private Equity Quantile Risk Data']}
    >
      <ValueAtRiskFundSummary
        varData={varData}
        timeSeriesData={timeSeriesData}
      />
      <ValueAtRiskPositionLevel data={varData} />
    </GeneralComponentErrorShield>
  );
};

export default PeValueAtRisk;
