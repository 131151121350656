import { ColumnDef } from '@tanstack/react-table';
import { ColumnData } from '../../../../tables/ultraTable/types/column.types';
import { createDefaultColumn } from '../../../../tables/ultraTable/utils/ultratable.utils';
import { GeneralFunctionType } from '../../../../tables/ultraTable/types/functions.types';
import {
  EditableCellWrapper,
  EditableFields,
  EditableFieldsProperties,
} from '../../../../tables/ultraTable/components/EditableCell';
import { DefaultColumn } from '../PositionsUpdatePortal.types';
import { FilterFn } from '@tanstack/react-table';
import { isLocalEditType } from '../../../../tables/ultraTable/components/FilterColumn';

const SIZE = (window.innerWidth - 100) / 8;
const MIN_SIZE = 100;

export interface PortalUpdatePosition extends EditableFields {
  position_id: string;
  gm_name: string;
  gm_name_long: string;
  fund_name: string;
  fund_id: string;
  expiry_date: string;
  mr_position: number;
  asset_subclass_name: string;
  instrument_id: number;
  instrument_type_id: number;
  is_etc: boolean;
  adepa_derivative_hedge: 'yes' | 'no' | 'loading' | 'error';
  market_price: number;
  is_coco?: 'yes' | 'no' | 'loading' | 'error';
  isin: string;
  editable_fields: EditableFieldsProperties[];
  client_price: number;
  cash_type: string;
  country_code: string;
  instrument: string;
  source: string;
  msci_sector_name: string;
  is_listed: string;
  rating: null | string;
  delta: number;
  is_etf: string;
  coupon_payment_period: string;
  is_loan: string;
  fund_assets_currency: null | string;
  asset_class_code: string;
  hedged_class: string;
  gm_id: number;
  client_price_date: string;
  maturity_time: number;
  pricing_assetsubclass: string;
  counterparty_type_id: null | number;
  market_value_base_currency: number;
  valid_isin: string;
  client_etf_type: null | string;
  expiry_check_skip: boolean;
  volatility: number;
  unrealised_pl_base: null | number;
  term: null | string;
  gm_client_name: string;
  yield: number;
  sector_icb_name: string;
  is_govt_owned: string;
  is_cb: string;
  outstanding_securities_six: number;
  dv01: number;
  annuity_price: number;
  unrealised_pl_local: null | number;
  outstanding_values_date: null | string;
  gm_id_new: number;
  day_count: null | number;
  client_country_code: string;
  forward_rate: null | number;
  msci_sector_code: number;
  is_subordinated: string;
  g_name: string;
  n_contract: string;
  has_voting_rights: string;
  days_to_maturity: null | number;
  raw_position: number;
  base_currency: string;
  asset_subclass_code: string;
  gamma: number;
  is_trs: string;
  mic_code: string;
  outstanding_capital_six: number;
  is_index_linked: null | string;
  swap_curve_risk_factors: null | string;
  hedge_trade: string;
  g_key: string;
  proxy_isin: string;
  six_borrower_category_description: string;
  portfolio_id: number;
  cs01: number;
  strike: null | number;
  outstanding_capital: number;
  risk_metric_value: number;
  counterparty_id: null | number;
  maturity_date: string;
  is_cds: string;
  share_class: null | string;
  cis: null | string;
  vega: number;
  sector_icb_id: number;
  six_borrower_category_id: string;
  sector_id: number;
  pricing_assetclass: string;
  issue_date: string;
  used_underlying_price: number;
  sector_name: string;
  interest_rate: null | number;
  market_value_local_currency: number;
  risk_factor: number;
  interest_type: null | string;
  oas: number;
  reportability: null | string;
  sheet_type: string;
  fund_assets: null | number;
  maturity_check_skip: boolean;
  is_spac: string;
  outstanding_securities: number;
  is_certificate: string;
  category_id: number;
}

const arrIncludesSomeExtended: FilterFn<any> = (
  row,
  columnId: string,
  filterValue: unknown[],
) => {
  let rowValue = row.getValue(columnId);

  if (isLocalEditType(rowValue)) {
    rowValue = rowValue.updated;
  }

  return filterValue.some((val) => {
    return rowValue === val;
  });
};

function testFalsey(val: any) {
  return val === undefined || val === null || val === '';
}

arrIncludesSomeExtended.autoRemove = (val: any) =>
  testFalsey(val) || !val?.length;

export const buildPotentialHedgePositionData = (originalData: any) => {
  const builtData: PortalUpdatePosition[] = [];
  originalData.positions.forEach((position: any) => {
    builtData.push({
      ...position,
    });
  });
  return builtData;
};

export const updateColumnTitles = (
  defaultColumns: DefaultColumn[],
  columns: ColumnData[],
): ColumnData[] => {
  return columns.map((column) => {
    const matchingDefaultColumn = defaultColumns.find(
      (defaultColumn) => defaultColumn.column_key === column.id,
    );

    if (matchingDefaultColumn) {
      return {
        ...column,
        title: matchingDefaultColumn.column_name,
      };
    }

    return column;
  });
};

export const portalUpdateColumns: ColumnData[] = [
  {
    id: 'position_id',
    title: 'Position ID',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'gm_name',
    title: 'GM Name',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'gm_name_long',
    title: 'General Name',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'fund_name',
    title: 'Fund Name',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'fund_id',
    title: 'Fund ID',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'expiry_date',
    title: 'Expiry Date',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'mr_position',
    title: 'Position Size',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'asset_subclass_name',
    title: 'Asset Subclass',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'instrument_id',
    title: 'Instrument ID',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'instrument_type_id',
    title: 'Instrument Type ID',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'is_etc',
    title: 'Is Listed',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'adepa_derivative_hedge',
    title: 'Is Hedge?',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'market_price',
    title: 'Market Price',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'is_coco',
    title: 'Is CoCo',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'isin',
    title: 'ISIN',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'client_price',
    title: 'Client Price',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'cash_type',
    title: 'Cash Type',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'country_code',
    title: 'Country Code',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'instrument',
    title: 'Instrument',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'source',
    title: 'Source',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'msci_sector_name',
    title: 'Sector',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'is_listed',
    title: 'Is Listed',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'rating',
    title: 'Rating',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'delta',
    title: 'Delta',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'is_etf',
    title: 'Is ETF',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'coupon_payment_period',
    title: 'Coupon Payment Period',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'is_loan',
    title: 'Is Loan',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'fund_assets_currency',
    title: 'Fund Assets Currency',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'asset_class_code',
    title: 'Asset Class Code',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'hedged_class',
    title: 'Hedged Class',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'gm_id',
    title: 'GM ID',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'client_price_date',
    title: 'Client Price Date',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'maturity_time',
    title: 'Maturity Time',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'pricing_assetsubclass',
    title: 'Pricing Asset Subclass',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'counterparty_type_id',
    title: 'Counterparty Type ID',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'market_value_base_currency',
    title: 'Market Value Base Currency',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'valid_isin',
    title: 'Valid ISIN',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'client_etf_type',
    title: 'Client ETF Type',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'expiry_check_skip',
    title: 'Expiry Check Skip',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'volatility',
    title: 'Volatility',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'unrealised_pl_base',
    title: 'Unrealised PL Base',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'term',
    title: 'Term',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'gm_client_name',
    title: 'GM Client Name',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'yield',
    title: 'Yield',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'sector_icb_name',
    title: 'Sector ICB Name',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'is_govt_owned',
    title: 'Is Govt Owned',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'is_cb',
    title: 'Is CB',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'outstanding_securities_six',
    title: 'Outstanding Securities Six',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'dv01',
    title: 'DV01',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'annuity_price',
    title: 'Annuity Price',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'unrealised_pl_local',
    title: 'Unrealised PL Local',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'outstanding_values_date',
    title: 'Outstanding Values Date',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'gm_id_new',
    title: 'GM ID New',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'day_count',
    title: 'Day Count',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'client_country_code',
    title: 'Client Country Code',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'forward_rate',
    title: 'Forward Rate',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'msci_sector_code',
    title: 'MSCI Sector Code',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'is_subordinated',
    title: 'Is Subordinated',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'g_name',
    title: 'G Name',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'n_contract',
    title: 'N Contract',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'has_voting_rights',
    title: 'Has Voting Rights',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'days_to_maturity',
    title: 'Days to Maturity',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'raw_position',
    title: 'Raw Position',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'base_currency',
    title: 'Base Currency',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'asset_subclass_code',
    title: 'Asset Subclass Code',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'gamma',
    title: 'Gamma',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'is_trs',
    title: 'Is TRS',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'mic_code',
    title: 'MIC Code',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'outstanding_capital_six',
    title: 'Outstanding Capital Six',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'is_index_linked',
    title: 'Is Index Linked',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'swap_curve_risk_factors',
    title: 'Swap Curve Risk Factors',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'hedge_trade',
    title: 'Hedge Trade',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'g_key',
    title: 'G Key',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'proxy_isin',
    title: 'Proxy ISIN',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'six_borrower_category_description',
    title: 'Six Borrower Category Description',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'portfolio_id',
    title: 'Portfolio ID',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'cs01',
    title: 'CS01',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'strike',
    title: 'Strike',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'outstanding_capital',
    title: 'Outstanding Capital',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'risk_metric_value',
    title: 'Risk Metric Value',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'counterparty_id',
    title: 'Counterparty ID',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'maturity_date',
    title: 'Maturity Date',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'is_cds',
    title: 'Is CDS',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'share_class',
    title: 'Share Class',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'cis',
    title: 'CIS',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'vega',
    title: 'Vega',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'sector_icb_id',
    title: 'Sector ICB ID',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'six_borrower_category_id',
    title: 'Six Borrower Category ID',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'sector_id',
    title: 'Sector ID',
    minSize: MIN_SIZE,
    size: SIZE,
    filterVariant: 'range',
  },
  {
    id: 'pricing_assetclass',
    title: 'Pricing Asset Class',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'issue_date',
    title: 'Issue Date',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'used_underlying_price',
    title: 'Used Underlying Price',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'range',
  },
  {
    id: 'sector_name',
    title: 'Sector Name',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'interest_rate',
    title: 'Interest Rate',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'range',
  },
  {
    id: 'market_value_local_currency',
    title: 'Market Value Local Currency',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'range',
  },
  {
    id: 'risk_factor',
    title: 'Risk Factor',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'range',
  },
  {
    id: 'interest_type',
    title: 'Interest Type',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'oas',
    title: 'OAS',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'range',
  },
  {
    id: 'reportability',
    title: 'Reportability',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'sheet_type',
    title: 'Sheet Type',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'fund_assets',
    title: 'Fund Assets',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'range',
  },
  {
    id: 'maturity_check_skip',
    title: 'Maturity Check Skip',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'is_spac',
    title: 'Is SPAC',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'outstanding_securities',
    title: 'Outstanding Securities',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'range',
  },
  {
    id: 'is_certificate',
    title: 'Is Certificate',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
  {
    id: 'category_id',
    title: 'Category ID',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'range',
  },

  {
    id: 'is_hybrid',
    title: 'Is Hybrid',
    minSize: MIN_SIZE,
    size: SIZE,
    filterFn: 'arrIncludesSome',
    filterVariant: 'select',
  },
];

export const buildHedgeDerivativeColumns = (
  columns: ColumnData[],
  editFn: GeneralFunctionType,
): ColumnDef<PortalUpdatePosition>[] => {
  const cols: ColumnDef<PortalUpdatePosition>[] = [];

  cols.push({
    id: 'index',
    size: 60,
    enableResizing: false,
    enableColumnFilter: false,
    enablePinning: true,
    header: ({ table }) => (
      <span style={{ fontSize: '1.4rem' }}>{table.getRowCount()}</span>
    ),
    cell: ({ table, row }) => {
      const filteredRowIndex =
        table.getFilteredRowModel().rows.findIndex((r) => r.id === row.id) + 1;

      return <span style={{ fontWeight: 600 }}>{filteredRowIndex}</span>;
    },
  });

  columns.forEach((dc: ColumnData) => {
    cols.push({
      id: dc.id,
      accessorKey: dc.id,
      cell: ({ getValue, row: { original, index }, column, table, cell }) => {
        return (
          <EditableCellWrapper
            getValue={getValue}
            original={original}
            index={index}
            column={column}
            table={table}
            cell={cell}
            fn={editFn}
          />
        );
      },
      sortingFn: 'customSortingFn',
      header: dc.title,
      footer: dc.id,
      ...(dc.enableResizing && { enableResizing: dc.enableResizing }),
      ...(dc.enableColumnFilter && {
        enableColumnFilter: dc.enableColumnFilter,
      }),
      minSize: dc.minSize,
      ...(dc.filterFn && { filterFn: dc.filterFn }),
      ...(dc.filterVariant === 'select' && {
        filterFn: arrIncludesSomeExtended,
      }),
      meta: {
        filterVariant: dc.filterVariant,
        short_title: dc.short_title ? dc.short_title : dc.title,
        editable: dc.editable, // Changed from isEditable to editable
      },
      ...(dc.size && { size: dc.size }),
    });
  });

  return cols;
};
