import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useFetchData from '../../../../hooks/useFetchData';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import {
  addAdditionalTitleBarComponents,
  toggleTitleBarLoading,
} from '../../../../redux/pages/actions';
import { Select } from '../../../../types/redux/pages/PagesStore';
import { APPEND_NEW_SELECT_TO_TITLEBAR } from '../../../../types/redux/pages/pageTypes';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import MonteCarloHistogramChart from './MonteCarloHistogram.component';
import MonteCarloTable from './MonteCarloTable.component';
import MonteCarloTimeSeriesChart from './MonteCarloTimeSeriesChart.component';
import MonteCarloCorrelationMatrix from './MonteCarloCorrelationMatrix.component';
import { Grid } from '@mui/material';

const MonteCarloVar: FC<FundInfoComponentProps> = ({
  fundId,
  positionDate,
}) => {
  const monteCarloVarData = useFetchData({
    url: `aggregate_monte_carlo_page/${fundId}/${positionDate}`,
    keyName: `aggregate_monte_carlo_page_${fundId}_${positionDate}`,
    makeFetch: positionDate && fundId ? true : false,
  });

  const [dropdownOptions, setDropdownOptions] = useState<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleTitleBarLoading(true));
    if (monteCarloVarData && !monteCarloVarData.isFetching) {
      // Get the position Names
      const positions = monteCarloVarData.data[0];
      const positionNames = Object.keys(positions).map((item: any) => item);
      // Filter out Portfolio.
      const filteredPositionNames = positionNames.filter(
        (item: any) => item !== 'Portfolio',
      );
      // filteredPositionNames.sort((a: any, b: any) => parseInt(a.replace('pe_dummy', '')) > parseInt(b.replace('pe_dummy', '')));
      filteredPositionNames.sort(
        (a: any, b: any) =>
          a.replace('pe_dummy', '') - b.replace('pe_dummy', ''),
      );
      // Add Portfolio to start
      filteredPositionNames.unshift('Portfolio');
      // only update if positions have changed.
      if (
        dropdownOptions &&
        filteredPositionNames.join(',') === dropdownOptions.join(',')
      ) {
        dispatch(toggleTitleBarLoading(false));
      } else {
        setDropdownOptions(filteredPositionNames);
      }
    }
  }, [monteCarloVarData]);

  // add a dropdown for the scenarios
  useEffect(() => {
    if (dropdownOptions) {
      const selectsToAdd: Select[] = [
        {
          titleBarTitle: 'Select Position',
          titleBarKey: 'selectPosition',
          values: dropdownOptions.map((item: any) => ({
            label: item,
            value: item,
          })),
          displayOnOverviewPage: false,
          aValueIsAlwaysSelected: true,
          currentValue: dropdownOptions[0],
        },
      ];
      dispatch(
        addAdditionalTitleBarComponents(
          selectsToAdd,
          APPEND_NEW_SELECT_TO_TITLEBAR,
        ),
      );
      dispatch(toggleTitleBarLoading(false));
    }
  }, [dropdownOptions]);
  const selectedPosition = useTitleBarSelect('selectPosition');

  return (
    <GeneralComponentErrorShield
      dataObjects={[monteCarloVarData]}
      customLoadingMessages={['Loading Private Equity Data...']}
      customErrorMessage="No Data Available."
    >
      <Grid container spacing={2} style={{ padding: 8 }}>
        <MonteCarloCorrelationMatrix
          dataForRender={monteCarloVarData}
          position={selectedPosition}
        />
        <MonteCarloTimeSeriesChart
          data={monteCarloVarData}
          selectedPosition={selectedPosition}
        />
      </Grid>
      <MonteCarloTable data={monteCarloVarData} position={selectedPosition} />
      <MonteCarloHistogramChart
        data={monteCarloVarData}
        position={selectedPosition}
        chartKey="pl_dist"
      />
      <MonteCarloHistogramChart
        data={monteCarloVarData}
        position={selectedPosition}
        chartKey="l_adjusted_pl_dist"
      />
    </GeneralComponentErrorShield>
  );
};

export default MonteCarloVar;
