import { useQuery } from '@tanstack/react-query';
import { getMarketsOverviewData } from './api.routes';

export function useMarketsOverview(selectedDate: string | null) {
  return useQuery({
    queryKey: ['markets-overview', selectedDate],
    queryFn: () => getMarketsOverviewData(selectedDate!),
    enabled: !!selectedDate,
  });
}
