import { FC, useEffect, useState } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import CounterpartyTables from './components/CounterpartyTables';
import { useDispatch } from 'react-redux';
import {
  addAdditionalTitleBarComponents,
  toggleTitleBarLoading,
} from '../../../../redux/pages/actions';
import RaptorLoading from '../../../feedback/RaptorLoading';
import {
  formatFundUrl,
  makeUniqueDataKey,
} from '../../../../utilities/urlBuilder';
import { Select } from '../../../../types/redux/pages/PagesStore';
import { APPEND_NEW_SELECT_TO_TITLEBAR } from '../../../../types/redux/pages/pageTypes';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import DisplayAreaCenteredWrapper from '../../../layout/utilities/displayAreaWrapper';
import { mainColors } from '../../../../styling/theme';
import { Typography } from '@mui/material';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';

const Counterparty: FC<FundInfoComponentProps> = ({
  fundId,
  section,
  pageId,
  positionDate,
}) => {
  const [counterpartyOptions, setCounterpartyOptions] = useState<any>(null);
  const [noCounterpartyData, setNoCounterpartyData] = useState<boolean>(false);
  const activeCounterpartyAgg = useFetchData({
    url: formatFundUrl('active_counterparty_agg', fundId, positionDate),
    keyName: makeUniqueDataKey(fundId, 'counterpartyAgg', positionDate),
    makeFetch: true,
  });

  const counterpartyGeneralMonitorExposure = useFetchData({
    url: formatFundUrl(
      'counterparty_general_exposure_monitor_agg',
      fundId,
      positionDate,
    ),
    keyName: makeUniqueDataKey(fundId, 'generalMonitorExposure', positionDate),
    makeFetch: true,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleTitleBarLoading(true));
    if (activeCounterpartyAgg && !activeCounterpartyAgg.isFetching) {
      setCounterpartyOptions(
        activeCounterpartyAgg.data[0].map((item: any) => ({
          label: item.counterparty_name,
          value: item.counterparty_id,
        })),
      );
    }
  }, [activeCounterpartyAgg]);

  // get any counterparty that is currently accepted
  const counterparty = useTitleBarSelect('counterpartySelection');

  // add counterparties
  useEffect(() => {
    if (counterpartyOptions && counterpartyOptions.length) {
      const selectsToAdd: Select[] = [
        {
          titleBarTitle: 'Counterparty',
          titleBarKey: 'counterpartySelection',
          values: counterpartyOptions,
          displayOnOverviewPage: false,
          aValueIsAlwaysSelected: true,
          currentValue: counterpartyOptions.includes(counterparty)
            ? counterparty
            : counterpartyOptions[0].value,
        },
      ];
      dispatch(
        addAdditionalTitleBarComponents(
          selectsToAdd,
          APPEND_NEW_SELECT_TO_TITLEBAR,
        ),
      );
      dispatch(toggleTitleBarLoading(false));
    } else if (counterpartyOptions && counterpartyOptions.length === 0) {
      setNoCounterpartyData(true);
      dispatch(toggleTitleBarLoading(false));
    }
  }, [counterpartyOptions]);

  const ready =
    activeCounterpartyAgg &&
    counterpartyGeneralMonitorExposure &&
    counterpartyOptions;
  const notLoading =
    ready &&
    !activeCounterpartyAgg.isFetching &&
    !counterpartyGeneralMonitorExposure.isFetching;
  return noCounterpartyData ? (
    <DisplayAreaCenteredWrapper>
      <Typography variant="h6" style={{ color: mainColors.mainBlue }}>
        No counterparty data available.
      </Typography>
    </DisplayAreaCenteredWrapper>
  ) : notLoading ? (
    <CounterpartyTables
      counterpartyOptions={counterpartyOptions}
      positionDate={positionDate || null}
      activeCounterpartyAgg={activeCounterpartyAgg}
      counterpartyGeneralMonitorExposure={counterpartyGeneralMonitorExposure}
      selectedFund={fundId}
    />
  ) : (
    <RaptorLoading centerWrap />
  );
};

export default Counterparty;
