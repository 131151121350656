import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useFetchData from '../../../../hooks/useFetchData';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import {
  addAdditionalTitleBarComponents,
  toggleTitleBarLoading,
} from '../../../../redux/pages/actions';
import { Select } from '../../../../types/redux/pages/PagesStore';
import { APPEND_NEW_SELECT_TO_TITLEBAR } from '../../../../types/redux/pages/pageTypes';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import PeHeatMapTable from './PeHeatMapTable.component';

const PeHeatMap: FC<FundInfoComponentProps> = ({ fundId, positionDate }) => {
  const [dropdownOptions, setDropdownOptions] = useState<any>(null);

  const peHeatMapData = useFetchData({
    url: `pe_heat_map/${fundId}/${positionDate}`,
    keyName: `pe_heat_map_${fundId}_${positionDate}`,
    makeFetch: positionDate && fundId ? true : false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleTitleBarLoading(true));
    if (peHeatMapData && !peHeatMapData.isFetching) {
      // Get the position Names
      const positions = peHeatMapData.data[0];
      const positionNames = Object.keys(positions).map((item: any) => item);
      // Filter out Portfolio.
      const filteredPositionNames = positionNames.filter(
        (item: any) => item !== 'Portfolio',
      );
      // filteredPositionNames.sort((a: any, b: any) => parseInt(a.replace('pe_dummy', '')) > parseInt(b.replace('pe_dummy', '')));
      filteredPositionNames.sort(
        (a: any, b: any) =>
          a.replace('pe_dummy', '') - b.replace('pe_dummy', ''),
      );
      // Add Portfolio to start
      filteredPositionNames.unshift('Portfolio');
      // only update if positions have changed.
      if (
        dropdownOptions &&
        filteredPositionNames.join(',') === dropdownOptions.join(',')
      ) {
        dispatch(toggleTitleBarLoading(false));
      } else {
        setDropdownOptions(filteredPositionNames);
      }
    }
  }, [peHeatMapData]);

  // add a dropdown for the scenarios
  useEffect(() => {
    if (dropdownOptions) {
      const selectsToAdd: Select[] = [
        {
          titleBarTitle: 'Select Position',
          titleBarKey: 'selectPosition',
          values: dropdownOptions.map((item: any) => ({
            label: item,
            value: item,
          })),
          displayOnOverviewPage: false,
          aValueIsAlwaysSelected: true,
          currentValue: dropdownOptions[0],
        },
      ];
      dispatch(
        addAdditionalTitleBarComponents(
          selectsToAdd,
          APPEND_NEW_SELECT_TO_TITLEBAR,
        ),
      );
      dispatch(toggleTitleBarLoading(false));
    }
  }, [dropdownOptions]);

  const selectedPosition = useTitleBarSelect('selectPosition');
  return (
    <GeneralComponentErrorShield
      dataObjects={[peHeatMapData]}
      customLoadingMessages={['Loading Private Equity Data...']}
      customErrorMessage="No Data Available."
    >
      <PeHeatMapTable
        data={peHeatMapData}
        selectedPosition={selectedPosition}
        fundId={fundId}
      />
    </GeneralComponentErrorShield>
  );
};

export default PeHeatMap;
