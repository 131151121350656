import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { updateSection } from '../../redux/pages/actions';
import { useEffect, useMemo } from 'react';
import withAuthorization from '../../hoc/withAuthorisation';
import { clientSectionsSelector } from '../../redux/pages/selectors';
import { UnconventionalRedirect } from '../Router';

const sectionExpections = ['dashboard', 'settings', 'documentation'];

interface AuthenticatedPageProps {
  Component: React.ComponentType<any>; // The component to render
  hardSection?: string;
}

const AuthenticatedPage = ({
  Component,
  hardSection, // this should be used on raidr - settings - documentation - dashboard
}: AuthenticatedPageProps): JSX.Element => {
  const location = useLocation();
  const { section } = useParams<{ section: string }>();
  const clientSections = useSelector(clientSectionsSelector);

  const dispatch = useDispatch();

  const usedSection = useMemo(() => {
    return hardSection || section;
  }, [hardSection, section]);

  // Update section whenever the section param changes
  useEffect(() => {
    if (usedSection) {
      dispatch(updateSection(usedSection));
    }
  }, [usedSection, dispatch]);

  // Handle the exception routes - settings, documentation, dashboard
  const nonSectionParams = location.pathname.split('/')[1];
  if (sectionExpections.includes(nonSectionParams)) {
    return <Component />;
  }

  // This handles for the raidr router as it does not use params as its on the raidr router not :sectionId:pageId Pages component
  // Tom Walsh [2024-12-06]  We can hopefully remove this once we have a better solution for the raidr router
  const pageId = location.pathname.split('/')[2];

  if (!usedSection) {
    return <Navigate to="/dashboard" replace />;
  }

  //
  if (!pageId) {
    return <UnconventionalRedirect hardSection={usedSection} />;
  }

  // Wrap the component with authorization logic
  return withAuthorization(Component, usedSection!, pageId!, clientSections);
};

export default AuthenticatedPage;
