import React from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import {
  activeDateRangeEndSelector,
  activeDateRangeStartSelector,
} from '../../../../redux/pages/selectors';
import { useSelector } from 'react-redux';
import StockSelectionPerformanceTable from './subComponents/StockSelectionPerformanceTable.component';
import StockSelectionPerformanceChart from './subComponents/StockSelectionPerformanceChart.component';

const StockSelectionPerformance: React.FC<FundInfoComponentProps> = ({
  fundId,
}) => {
  const fromDate = useSelector(activeDateRangeStartSelector);
  const toDate = useSelector(activeDateRangeEndSelector);

  const PerformanceAttributionData = useFetchData({
    url: `/performance_attribution/${fundId}/${fromDate}/${toDate}`,
    keyName: `performance_attribution_${fundId}_${fromDate}_${toDate}`,
    makeFetch:
      fundId !== undefined &&
      fromDate !== null &&
      fromDate !== '' &&
      toDate !== null &&
      toDate !== '',
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[PerformanceAttributionData]}
      customLoadingMessages={[
        'Loading Performance Attribution Data',
        `${fromDate} to ${toDate}`,
        'Just a few more seconds...',
      ]}
      customErrorMessage="No Data Available."
    >
      <StockSelectionPerformanceChart data={PerformanceAttributionData} />
      <StockSelectionPerformanceTable data={PerformanceAttributionData} />
    </GeneralComponentErrorShield>
  );
};

export default StockSelectionPerformance;
