import {
  DatePicker,
  InfoToggle,
  Input,
  PagesStore,
  Select,
  TitleBarComponent,
} from '../../types/redux/pages/PagesStore';
import {
  ADD_FUNDS_DATA_FAILURE,
  ADD_FUNDS_DATA_START,
  ADD_FUNDS_DATA_SUCCESS,
  APPEND_NEW_DATE_PICKER_TO_TITLEBAR,
  APPEND_NEW_INFOTOGGLE_TO_TITLEBAR,
  APPEND_NEW_INPUT_TO_TITLEBAR,
  APPEND_NEW_SELECT_TO_TITLEBAR,
  FETCH_CLIENT_CONFIG_FAILURE,
  FETCH_CLIENT_CONFIG_START,
  FETCH_CLIENT_CONFIG_SUCCESS,
  PageAction,
  REMOVE_DATE_PICKER_FROM_TITLEBAR,
  REMOVE_INFOTOGGLE_FROM_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
  TOGGLE_OPENING_ANIMATION,
  TOGGLE_POSITION_DATE_RANGE_SHOWING,
  TOGGLE_POSITION_DATE_SHOWING,
  TOGGLE_TITLEBAR_LOADING,
  UPDATE_ACTIVE_DATE,
  UPDATE_ACTIVE_DATE_RANGE_END,
  UPDATE_ACTIVE_DATE_RANGE_START,
  UPDATE_ACTIVE_FUND,
  UPDATE_ACTIVE_PAGE,
  UPDATE_ACTIVE_SECTION,
  UPDATE_AVAILABLE_DATES,
  UPDATE_CURRENT_FUND_SEARCH_STRING,
  UPDATE_FILTERED_FUNDS,
  UPDATE_SPECIFIC_TITLEBAR_COMPONENT,
  UPDATE_SPECIFIC_TITLEBAR_INPUT,
  UPDATE_TITLE_BAR_SELECT,
} from '../../types/redux/pages/pageTypes';
import {
  generateAllowedFundRoutes,
  generateAllowedPageRoutesWherePagesAllowFunds,
} from '../../routes/utils/allowedRouteUtils';
import generateAppropriateTitleBarComponentsByPage from './utilityFunctions/generateAppropriateTitleBarComponentsByPage';
import updateRelevantTitleBarSelect from './utilityFunctions/updateRelevantTitleBarSelect';

/**
 * The pages reducer deals with layout issues relating to the pages.
 * It also controls what is displayed in the titlebar, for example, what selects are there, if a status is showing etc.
 */

const filterComponent =
  (componentsToDelete: string[]) => (component: TitleBarComponent) =>
    !componentsToDelete.includes(component.titleBarKey);

export default function pagesReducer(
  state: PagesStore = {
    activeSection: '',
    activePage: '',
    activePageArea: '',
    activePageDisplayName: '',
    activeFundsBySection: {},
    titleBarComponents: {
      selects: [],
      inputs: [],
      datePickers: [],
      showStatus: false,
      loading: false,
      activeDate: '',
      activeDateRangeStart: '',
      activeDateRangeEnd: '',
      availableDates: [],
      infoToggles: [],
    },
    funds: null,
    clientConfiguration: {
      configId: '',
      pages: null,
      sections: null,
      isFetching: false,
      error: null,
      allowedPageRoutesWherePagesAllowFunds: [],
      allowedFundRoutes: [],
      openingPage: null,
    },
    openingAnimation: true,
    currentFundSearchString: '',
  },
  action: PageAction,
): PagesStore {
  switch (action.type) {
    case UPDATE_ACTIVE_SECTION:
      return {
        ...state,
        activeSection: action.payload,
      };
    case UPDATE_ACTIVE_PAGE:
      const titleBarComponents = generateAppropriateTitleBarComponentsByPage(
        action.payload.pageId,
        state.clientConfiguration,
      );

      return {
        ...state,
        currentFundSearchString: '',
        activePage: action.payload.pageId,
        activePageArea: action.payload.pageArea,
        activePageDisplayName: action.payload.explicitPageName
          ? action.payload.explicitPageName
          : state.clientConfiguration.pages
            ? state.clientConfiguration.pages[action.payload.pageId]?.pageTitle
            : '',
        titleBarComponents,
      };
    case UPDATE_ACTIVE_FUND:
      return {
        ...state,
        activeFundsBySection: {
          ...state.activeFundsBySection,
          [action.payload.section]: action.payload.fundName,
        },
        titleBarComponents: {
          ...state.titleBarComponents,
          activeDate: action.payload.date,
        },
      };
    case ADD_FUNDS_DATA_START:
      return {
        ...state,
        funds: {
          ...state.funds,
          [`${action.payload.fund}_${action.payload.rsResource}`]: {
            requestedData: {
              isFetching: true,
              error: null,
              data: [],
            },
            fundData: [],
            filteredFunds: [],
          },
        },
      };
    case ADD_FUNDS_DATA_SUCCESS:
      return {
        ...state,
        funds: {
          ...state.funds,
          [`${action.payload.fund}_${action.payload.rsResource}`]: {
            requestedData: {
              isFetching: false,
              error: null,
              data: action.payload.data,
            },
            fundData: action.payload.data.map((datum: any) => ({
              id: datum.fund_name,
              name: datum.fund_name_full,
            })),
            filteredFunds: [],
          },
        },
      };
    case ADD_FUNDS_DATA_FAILURE:
      return {
        ...state,
        funds: {
          ...state.funds,
          [`${action.payload.fund}_${action.payload.rsResource}`]: {
            requestedData: {
              isFetching: false,
              error: action.payload.error,
              data: [],
            },
            fundData: [],
            filteredFunds: [],
          },
        },
      };
    case UPDATE_FILTERED_FUNDS:
      if (
        !state.funds ||
        !state.funds[`${action.payload.fund}_${action.payload.rsResource}`]
      )
        return state;
      return {
        ...state,
        funds: {
          ...state.funds,
          [`${action.payload.fund}_${action.payload.rsResource}`]: {
            ...state.funds![
              `${action.payload.fund}_${action.payload.rsResource}`
            ],
            filteredFunds: action.payload.filteredFunds,
          },
        },
      };
    case FETCH_CLIENT_CONFIG_START: {
      return {
        ...state,
        clientConfiguration: {
          ...state.clientConfiguration,
          isFetching: true,
        },
      };
    }
    case FETCH_CLIENT_CONFIG_SUCCESS: {
      return {
        ...state,
        clientConfiguration: {
          ...state.clientConfiguration,
          ...action.payload,
          isFetching: false,
          allowedPageRoutesWherePagesAllowFunds:
            generateAllowedPageRoutesWherePagesAllowFunds(action.payload),
          allowedFundRoutes: generateAllowedFundRoutes(
            action.payload,
            state.funds,
          ),
        },
      };
    }
    case FETCH_CLIENT_CONFIG_FAILURE:
      return {
        ...state,
        clientConfiguration: {
          ...state.clientConfiguration,
          isFetching: false,
          error: action.payload,
        },
      };

    case UPDATE_TITLE_BAR_SELECT:
      return {
        ...state,
        titleBarComponents: {
          ...state.titleBarComponents,
          selects: updateRelevantTitleBarSelect({
            ...action.payload,
            selects: state.titleBarComponents.selects,
          }),
        },
      };
    case APPEND_NEW_INPUT_TO_TITLEBAR:
      // first step is to replace any selects that are currently accounted for
      const inputsToDelete = action.payload.components.map(
        (component: Input) => component.titleBarKey,
      );

      const inputsFiltered = state.titleBarComponents.inputs.filter(
        filterComponent(inputsToDelete),
      );

      return {
        ...state,
        titleBarComponents: {
          ...state.titleBarComponents,
          inputs: [...inputsFiltered, ...action.payload.components],
        },
      };

    case APPEND_NEW_SELECT_TO_TITLEBAR:
      const selectsToDelete = action.payload.components.map(
        (component: Select) => component.titleBarKey,
      );

      const selectsFiltered = state.titleBarComponents.selects.filter(
        filterComponent(selectsToDelete),
      );

      const newState = {
        ...state,
        titleBarComponents: {
          ...state.titleBarComponents,
          selects: [...selectsFiltered, ...action.payload.components],
        },
      };

      return newState;

    case REMOVE_SELECT_FROM_TITLEBAR:
      return {
        ...state,
        titleBarComponents: {
          ...state.titleBarComponents,
          selects: state.titleBarComponents.selects.filter(
            (select: Select) => select.titleBarKey !== action.payload,
          ),
        },
      };

    case APPEND_NEW_INFOTOGGLE_TO_TITLEBAR:
      const infoTogglesToDelete = action.payload.components.map(
        (component: InfoToggle) => component.titleBarKey,
      );
      const infoTogglesFiltered = state.titleBarComponents.infoToggles.filter(
        filterComponent(infoTogglesToDelete),
      );
      return {
        ...state,
        titleBarComponents: {
          ...state.titleBarComponents,
          infoToggles: [...infoTogglesFiltered, ...action.payload.components],
        },
      };

    case REMOVE_INFOTOGGLE_FROM_TITLEBAR:
      return {
        ...state,
        titleBarComponents: {
          ...state.titleBarComponents,
          infoToggles: state.titleBarComponents.infoToggles.filter(
            (infoToggle: InfoToggle) =>
              infoToggle.titleBarKey !== action.payload,
          ),
        },
      };
    case REMOVE_DATE_PICKER_FROM_TITLEBAR:
      return {
        ...state,
        titleBarComponents: {
          ...state.titleBarComponents,
          datePickers: state.titleBarComponents.datePickers.filter(
            (db: DatePicker) => db.titleBarKey !== action.payload,
          ),
        },
      };
    case APPEND_NEW_DATE_PICKER_TO_TITLEBAR:
      const datePickersToDelete = action.payload.components.map(
        (component: DatePicker) => component.titleBarKey,
      );

      const datePickersFiltered = state.titleBarComponents.datePickers.filter(
        filterComponent(datePickersToDelete),
      );

      return {
        ...state,
        titleBarComponents: {
          ...state.titleBarComponents,
          datePickers: [...datePickersFiltered, ...action.payload.components],
        },
      };
    case UPDATE_ACTIVE_DATE:
      return {
        ...state,
        titleBarComponents: {
          ...state.titleBarComponents,
          activeDate: action.payload || '',
        },
      };
    case UPDATE_ACTIVE_DATE_RANGE_START:
      return {
        ...state,
        titleBarComponents: {
          ...state.titleBarComponents,
          activeDateRangeStart: action.payload || '',
        },
      };
    case UPDATE_ACTIVE_DATE_RANGE_END:
      return {
        ...state,
        titleBarComponents: {
          ...state.titleBarComponents,
          activeDateRangeEnd: action.payload || '',
        },
      };
    case UPDATE_AVAILABLE_DATES:
      return {
        ...state,
        titleBarComponents: {
          ...state.titleBarComponents,
          availableDates: action.payload || [],
        },
      };
    case TOGGLE_TITLEBAR_LOADING:
      return {
        ...state,
        titleBarComponents: {
          ...state.titleBarComponents,
          loading: action.payload,
        },
      };
    case TOGGLE_OPENING_ANIMATION:
      return {
        ...state,
        openingAnimation: !state.openingAnimation,
      };
    case UPDATE_SPECIFIC_TITLEBAR_INPUT:
      return {
        ...state,
        titleBarComponents: {
          ...state.titleBarComponents,
          inputs: state.titleBarComponents.inputs.map((input: Input) => {
            if (input.titleBarKey === action.payload.inputKey) {
              return {
                ...input,
                currentValue: action.payload.newInputValue,
              };
            } else {
              return input;
            }
          }),
        },
      };

    case TOGGLE_POSITION_DATE_SHOWING:
      if (
        !state.clientConfiguration ||
        !state.clientConfiguration.pages ||
        !state.activePage
      )
        return state;
      return {
        ...state,
        clientConfiguration: {
          ...state.clientConfiguration,
          pages: {
            ...state.clientConfiguration.pages,
            [state.activePage]: {
              ...state.clientConfiguration.pages[state.activePage],
              usePositionDatePicker: action.payload,
            },
          },
        },
      };

    case TOGGLE_POSITION_DATE_RANGE_SHOWING:
      if (
        !state.clientConfiguration ||
        !state.clientConfiguration.pages ||
        !state.activePage
      )
        return state;
      return {
        ...state,
        clientConfiguration: {
          ...state.clientConfiguration,
          pages: {
            ...state.clientConfiguration.pages,
            [state.activePage]: {
              ...state.clientConfiguration.pages[state.activePage],
              usePositionDatePickerRange: action.payload,
            },
          },
        },
      };

    case UPDATE_CURRENT_FUND_SEARCH_STRING:
      return {
        ...state,
        currentFundSearchString: action.payload,
      };

    case UPDATE_SPECIFIC_TITLEBAR_COMPONENT:
      const newSelects = state.titleBarComponents.selects.map(
        (select: Select) => {
          if (select.titleBarKey === action.payload.componentKey) {
            select.currentValue = action.payload.newValue as string;
          }
          return select;
        },
      );
      const newInputs = state.titleBarComponents.inputs.map((input: Input) => {
        if (input.titleBarKey === action.payload.componentKey) {
          input.currentValue = action.payload.newValue as string | number;
        }
        return input;
      });
      const newDatePickers = state.titleBarComponents.datePickers.map(
        (dp: DatePicker) => {
          if (dp.titleBarKey === action.payload.componentKey) {
            dp.currentValue = action.payload.newValue as string;
          }
          return dp;
        },
      );
      return {
        ...state,
        titleBarComponents: {
          ...state.titleBarComponents,
          selects: newSelects,
          inputs: newInputs,
          datePickers: newDatePickers,
        },
      };
    default:
      return state;
  }
}
