import React from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import FxRatesTable from './subComponents/FxRatesTable.component';

const FxRates: React.FC<FundInfoComponentProps> = ({
  fundId,
  fundName,
  positionDate,
}) => {
  const FxRatesData = useFetchData({
    url: `/fund_fx_data/${fundId}/${positionDate}`,
    keyName: `fund_fx_data_${fundId}_${positionDate}`,
    makeFetch: fundId && positionDate ? true : false,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[FxRatesData]}
      customLoadingMessages={['Loading FX Data']}
      customErrorMessage="No Data Available."
    >
      <FxRatesTable
        data={FxRatesData}
        fundName={fundName}
        positionDate={positionDate}
      />
    </GeneralComponentErrorShield>
  );
};

export default FxRates;
