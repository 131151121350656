//@ts-nocheck

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './fonts/Jost-Regular.ttf';
import './fonts/Jost-Light.ttf';
import './fonts/Jost-Bold.ttf';
import './fonts/Jost-ExtraBold.ttf';
import { BrowserRouter, useLocation } from 'react-router-dom';
import store from './redux/Store';
import App from './App';
import { useEffect } from 'react';

const EnsureBasename = ({ children, basename }) => {
  const location = window.location;

  useEffect(() => {
    if (!location.pathname.startsWith(basename)) {
      const fullPath = `${basename}${location.pathname}`;
      window.location.replace(fullPath); // Redirect to correct path
    }
  }, [basename, location.pathname]);

  return children;
};

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);
