import React, { ErrorInfo, ReactNode } from 'react';
import { flexify } from '../../utilities/cssMixins';
import { Navigate } from 'react-router-dom';
import { Typography } from '@mui/material';

interface ErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  public state = {
    redirect: '',
    hasError: false,
  };
  public static getDerivedStateFromError() {
    return { hasError: true };
  }
  // set the types for error  and info
  public componentDidCatch(error: Error, info: ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, info);
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to="/" />;
    }
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div style={{ height: '80vh', width: '100%', ...flexify() }}>
          <Typography variant="h3">
            An error occurred, and a report has been submitted to the site
            administrator. Please try refreshing your page.
          </Typography>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
