import { FC } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import CarbonContributersPanel from './CarbonContributersPanel.component';
import CarbonFootprintOverviewPanel from './CarbonFootprintPanel.component';
import ParisAlignedPanel from './ParisAlignedPanel.component';
import NonPremiumSubscriptionDisplayMessage, {
  doesClientHavePremiumPackage,
} from '../NonPremiumSubscriptionDisplay/NonPremiumSubscriptionDisplayMessage.component';
import { Grid } from '@mui/material';

const PortfolioCarbonEmissions: FC<FundInfoComponentProps> = ({
  fundName,
  fundId,
  positionDate,
}) => {
  const esgData = useFetchData({
    url: `get_fund_esg_data/${fundId}/${positionDate}`,
    keyName: `${fundId}_esg_fund_data_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  const doesClientHavePremiumSubscription =
    doesClientHavePremiumPackage(esgData);

  return (
    <GeneralComponentErrorShield
      dataObjects={[esgData]}
      customLoadingMessages={['Loading ESG Data...', '...']}
      customErrorMessage={'No ESG Data Found'}
    >
      {doesClientHavePremiumSubscription ? (
        <Grid container spacing={2} style={{ padding: 8 }}>
          <CarbonFootprintOverviewPanel
            data={esgData}
            fundId={fundId}
            fundName={fundName}
            positionDate={positionDate}
          />
          <ParisAlignedPanel data={esgData} fundId={fundId} />
          <CarbonContributersPanel data={esgData} />
        </Grid>
      ) : (
        <NonPremiumSubscriptionDisplayMessage />
      )}
    </GeneralComponentErrorShield>
  );
};

export default PortfolioCarbonEmissions;
