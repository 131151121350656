import { sanitiseEditorText } from './editor.utils';

// This will reformat old DB data to fit the new format are remove the need for nested br replaces in the pdf generator
// The sanitiseEditorText will make sure all content is formatted correctly for the pdf generator
export function formatReviewContentFromResponse(response: any) {
  const formattedData = response.data.map((item: any) => {
    return {
      ...item,
      target: item.target.map((targetItem: any) => ({
        ...targetItem,
        content: sanitiseEditorText(targetItem.content),
      })),
      comparison: item.comparison.map((comparisonItem: any) => ({
        ...comparisonItem,
        content: sanitiseEditorText(comparisonItem.content),
      })),
    };
  });

  return formattedData;
}
