import { FC } from 'react';
import FactSheetsOverview from './overview/FactSheetsOverview.component';
import { Navigate, Route } from 'react-router-dom';

const FactSheets: FC = (props) => {
  return (
    <>
      <Route
        path="/raidr/fact-sheets"
        element={<Navigate to="/raidr/fact-sheets/overview" />}
      />
      <Route
        path="/raidr/fact-sheets/overview"
        element={<FactSheetsOverview />}
      />
    </>
  );
};

export default FactSheets;
