import React, { useEffect } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import VarNewMainChart from './VarNewMainChart/VarNewMainChart.component';
import VarNewMainTable from './VarNewMainTable/VarNewMainTable.component';
import VarNewBreakdownTableComponent from './varNewBreakdownTable/VarNewBreakdownTable.component';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../../../../types/redux/pages/PagesStore';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
} from '../../../../redux/pages/actions';
import {
  APPEND_NEW_SELECT_TO_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
} from '../../../../types/redux/pages/pageTypes';
import {
  activeSectionSelector,
  createActiveFundSelectorBySection,
  titleBarSelectsSelector,
} from '../../../../redux/pages/selectors';

const ValueAtRiskNew: React.FC<FundInfoComponentProps> = ({
  section,
  pageId,
  fundName,
  fundId,
  ...props
}) => {
  const dispatch = useDispatch();

  // On the VAR page the fund fetch is done by the parent component, it is not fetched = UPDATE_ACTIVE_FUND =>  before titlebar components so when is set after the titlebar compoennts the titlebar selects are removed
  const currentSection = useSelector(activeSectionSelector);

  const activeFund = useSelector(
    createActiveFundSelectorBySection(currentSection),
  );

  // Add the select components to the title bar
  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        titleBarTitle: 'Risk Metric: ',
        titleBarKey: 'riskMetric',
        values: [
          {
            label: 'VaR',
            value: 'var',
          },
          {
            label: 'C-VaR',
            value: 'cvar',
          },
          {
            label: 'D-VaR',
            value: 'dvar',
          },
        ],
        displayOnOverviewPage: false,
        aValueIsAlwaysSelected: true,
        currentValue: 'var',
      },
      {
        titleBarTitle: 'Risk Output: ',
        titleBarKey: 'riskOutput',
        values: [
          {
            label: 'Cash',
            value: 'Cash',
          },
          {
            label: 'Relative',
            value: 'relative',
          },
          {
            label: 'Absolute',
            value: 'absolute',
          },
        ],
        displayOnOverviewPage: false,
        aValueIsAlwaysSelected: true,
        currentValue: 'absolute',
      },
    ];

    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR,
      ),
    );

    return () => {
      dispatch(
        removeTitleBarComponent('riskMetric', REMOVE_SELECT_FROM_TITLEBAR),
      );
      dispatch(
        removeTitleBarComponent('riskOutput', REMOVE_SELECT_FROM_TITLEBAR),
      );
    };
  }, [dispatch, activeFund]);

  const basicDataAggFetchString = `basic_data_agg/${fundId}/${
    props.positionDate || ''
  }`;
  const portfolioNameAggString = `portfolio_name_agg/${fundId}/`;
  const fundVarDataString = `fund_VaR_data/${fundId}/${
    props.positionDate || ''
  }`;
  const basicDataAgg = useFetchData({
    url: basicDataAggFetchString,
    keyName: `basic_data_agg_var_${fundId}_${
      props.positionDate || formatDateForCheckingState(new Date())
    }`,
    makeFetch: true,
  });
  const portfolioNameAgg = useFetchData({
    url: portfolioNameAggString,
    keyName: `portfolio_name_agg_${fundId}_${
      props.positionDate || formatDateForCheckingState(new Date())
    }`,
    makeFetch: true,
  });
  const fundVarData = useFetchData({
    url: fundVarDataString,
    keyName: `fund_var_data_${fundId}_${
      props.positionDate || formatDateForCheckingState(new Date())
    }`,
    makeFetch: true,
  });
  const breakdownData = useFetchData({
    url: `/position_var_table/${fundId}/${props.positionDate}`,
    keyName: `position_var_table_${fundId}_${
      props.positionDate || formatDateForCheckingState(new Date())
    }`,
    makeFetch: props.positionDate ? true : false,
  });

  return (
    <GeneralComponentErrorShield
      customLoadingMessages={[
        'Loading your var data',
        'This may take a few minutes...',
      ]}
      dataObjects={[basicDataAgg, portfolioNameAgg, fundVarData, breakdownData]}
    >
      <VarNewMainTable
        fundName={fundName}
        dataObjects={{ basicDataAgg, portfolioNameAgg, fundVarData }}
      />
      <VarNewMainChart
        fundName={fundName}
        dataObjects={{ basicDataAgg, portfolioNameAgg, fundVarData }}
      />
      <VarNewBreakdownTableComponent
        data={breakdownData}
        positionDate={props.positionDate ?? ''}
      />
    </GeneralComponentErrorShield>
  );
};

export default ValueAtRiskNew;
