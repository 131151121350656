// Helper function to append data if it exists and is not empty
export const formSafeAppend = (
  formData: FormData,
  key: string,
  value?: string | null,
): void => {
  if (value) {
    formData.append(key, value);
  }
};
