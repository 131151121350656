import { FunctionComponent } from 'react';
import { flexify } from '../../utilities/cssMixins';
import { Typography } from '@mui/material';

const ErrorBoundaryMessage: FunctionComponent = () => {
  return (
    <div style={{ height: '80vh', width: '100%', ...flexify() }}>
      <Typography variant="h3">
        An error occurred, and a report has been submitted to the site
        administrator. Please try refreshing your page.
      </Typography>
    </div>
  );
};

export default ErrorBoundaryMessage;
