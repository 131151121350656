import React from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import IndicatorCoverateMonitorTable from './IndicatorCoverageratioMonitorTable.component';

export interface IndicatorCoverageRatioMonitorUnderlyingTableData {
  gmid: string;
  gross_exposure_percentage: number;
  indicator: string;
  isin: string;
  position_id: string;
  position_name: string;
  value: string;
}

export interface IndicatorCoverageRatioMonitorTableData {
  coverage_ratio_percentage: number;
  description: string;
  indicator: string;
  indicator_name: string;
  limit: number;
  limit_type: string;
  pass_fail: string;
  total_covered_exposure_percentage: number;
  total_gross_exposure_percentage: number;
  unit: string;
  value: string;
  underlying: IndicatorCoverageRatioMonitorUnderlyingTableData[];
}

const IndicatorCoverageRatioMonitor: React.FC<FundInfoComponentProps> = ({
  fundId,
  positionDate,
}) => {
  const indicatorCoverageMonitorData = useFetchData({
    url: `indicator_coverage_ratio_monitor/${fundId}/${positionDate}`,
    keyName: `indicator_coverage_ratio_monitor${fundId}_${positionDate}`,
    makeFetch: fundId && positionDate ? true : false,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[indicatorCoverageMonitorData]}
      customLoadingMessages={['Loading Indicator Compliance Data']}
      customErrorMessage="No Data Available."
    >
      <IndicatorCoverateMonitorTable data={indicatorCoverageMonitorData} />
    </GeneralComponentErrorShield>
  );
};

export default IndicatorCoverageRatioMonitor;
