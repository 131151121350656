import React from 'react';
import SelectAFundPage from './selectAFundPage/component';
import { createSectionByIdSelector } from '../../../redux/pages/selectors';
import { useSelector } from 'react-redux';
import { Section } from '../../../types/server-data/ClientConfig';
import { useParams } from 'react-router-dom';
import { PortalFetchWrapper } from '../manco/positionUpdatePortal/PortalFetchWrapper';

interface GeneralSelectAFundOverview {
  pageId: string;
}

export const GeneralSelectAFundOverview: React.FC<
  GeneralSelectAFundOverview
> = ({ pageId, ...props }) => {
  const { section } = useParams<any>();

  const sectionDetailsSelector = createSectionByIdSelector(section!);
  const sectionDetails: Section | null = useSelector(sectionDetailsSelector);

  // Handles the fundselection for the position update portal
  if (pageId === 'position-update-portal') {
    return (
      <PortalFetchWrapper
        {...props}
        component={
          <SelectAFundPage
            section={section!}
            fundsForUse={sectionDetails?.fundTypes || 'UCITS'}
          />
        }
      />
    );
  }
  return (
    <SelectAFundPage
      section={section!}
      fundsForUse={sectionDetails?.fundTypes || 'UCITS'}
    />
  );
};

export default GeneralSelectAFundOverview;
