import { useEffect } from 'react';
import RsSwipe from '../../../images/RsSwipe';
import { makeStyles } from '@mui/styles';
import { Outlet } from 'react-router-dom';
import axios from 'axios';
import { fetchClientConfig } from '../../../redux/pages/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  configNameSelector,
  isLoggedInSelector,
} from '../../../redux/auth/selectors';

const useStyles = makeStyles({
  loginPage: {
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rsSwipe: {
    position: 'fixed',
    right: 0,
    height: '100vh',
    width: '100vw',
  },
});

const AuthLayout = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isLoggedInSelector);

  const configName = useSelector(configNameSelector);

  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  useEffect(() => {
    if (isAuthenticated && configName) {
      dispatch(fetchClientConfig(configName, source));
    }
  }, [isAuthenticated]);

  return (
    <main className={classes.loginPage}>
      <Outlet />
      <RsSwipe className={classes.rsSwipe} />
    </main>
  );
};

export default AuthLayout;
