import { useQuery } from '@tanstack/react-query';
import {
  getKIDContentData,
  getKIDSGeneratorOverviewData,
  getKidsReviewData,
} from './api.routes';

export function useKIDSOverviewData() {
  return useQuery({
    queryKey: ['kids-overview-data'],
    queryFn: getKIDSGeneratorOverviewData,
  });
}

export function useKIDSContentData(
  fundId: string | null,
  isin: string | null,
  documentLanguage: string | null,
  version?: string | null,
) {
  return useQuery({
    queryKey: ['kids-content-data', fundId, isin, documentLanguage, version],
    queryFn: () =>
      getKIDContentData(fundId!, isin!, documentLanguage!, version), // Enabled ensures all parameters are present
    enabled: !!fundId && !!isin && !!documentLanguage,
  });
}

export function useKIDSReviewData(
  fundId: string | null,
  isin: string | null,
  documentLanguage: string | null,
  targetVersion: string | null,
  comparisonVersion: string | null,
) {
  return useQuery({
    queryKey: [
      'kids-review-data',
      fundId,
      isin,
      documentLanguage,
      targetVersion,
      comparisonVersion,
    ],
    queryFn: () =>
      getKidsReviewData(
        fundId!,
        isin!,
        documentLanguage!,
        targetVersion!,
        comparisonVersion!,
      ), // Enabled ensures all parameters are present
    enabled:
      !!fundId &&
      !!isin &&
      !!documentLanguage &&
      !!targetVersion &&
      !!comparisonVersion,
  });
}
