import { FC } from 'react';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import EmissionsCostsChart from './EmissionsCostsChart.component';
import ScoreStressTestChart from './ScoreStressTestChart.component';
import useFetchData from '../../../../hooks/useFetchData';
import { useAdditionalTitleBarSelect } from '../../../../hooks/useAdditionalTitleBarComponents';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import { Grid } from '@mui/material';

interface EmissionsChartData {
  name: string;
  scope1: number;
  scope2: number;
  scope3: number;
  totalC02: number;
  airPol: number;
  airPolName: string;
}

function buildEmissionsChartData(data: any): EmissionsChartData[] {
  if (!data) return [];
  if (!('data' in data)) return [];
  if (!data.data.length) return [];
  if (!('emission_data' in data.data[0])) return [];
  const emissionData = data.data[0].emission_data;
  const chartData: EmissionsChartData[] = [];
  // Add €100/ tonne data
  chartData.push({
    name: emissionData['scope 1'][0][0],
    scope1: emissionData['scope 1'][0][1],
    scope2: emissionData['Scope 2'][0][1],
    scope3: emissionData['Scope 3'][0][1],
    totalC02: emissionData['Total CO2'][0][1],
    airPol: emissionData['SOX / NOX etc'][0][1],
    airPolName: emissionData['SOX / NOX etc'][0][0],
  });
  chartData.push({
    name: emissionData['scope 1'][1][0],
    scope1: emissionData['scope 1'][1][1],
    scope2: emissionData['Scope 2'][1][1],
    scope3: emissionData['Scope 3'][1][1],
    totalC02: emissionData['Total CO2'][1][1],
    airPol: emissionData['SOX / NOX etc'][1][1],
    airPolName: emissionData['SOX / NOX etc'][1][0],
  });
  return chartData;
}

function buildChartData(
  data: any,
  chartType:
    | 'e_stress_test'
    | 's_stress_test'
    | 'g_stress_test'
    | 'esg_stress_test',
  disordered: boolean,
): chartDataType[] {
  if (!data) return [];
  if (!('data' in data)) return [];
  if (!data.data.length) return [];
  if (!('esg_data' in data.data[0])) return [];
  const esgData = disordered
    ? data.data[0].disorderly_esg_data
    : data.data[0].esg_data;
  const chartData: chartDataType[] = [];
  esgData[chartType].forEach((value: any) => {
    chartData.push({
      name: value[0],
      score: value[1],
    });
  });
  return chartData;
}

interface chartDataType {
  name: string;
  score: number;
}
const ESGStressTests: FC<FundInfoComponentProps> = (props) => {
  const stressTestData = useFetchData({
    keyName: props.positionDate
      ? `${props.fundId}_esg_stress_test_data_${props.positionDate}`
      : `${props.fundId}_esg_stress_test_data`,
    url: props.positionDate
      ? `manco_level_esg_stress_test_data/${props.fundId}/${props.positionDate}`
      : `manco_level_esg_stress_test_data/${props.fundId}`,
    makeFetch: true,
  });

  useAdditionalTitleBarSelect(
    {
      displayOnOverviewPage: true,
      titleBarKey: 'key1',
      titleBarTitle: 'Stress Test Data: ',
      values: [
        {
          label: 'Orderly Transition',
          value: 'Standard ESG Data',
        },
        {
          label: 'Disorderly Transition',
          value: 'Disorderly Esg Data',
        },
        {
          label: 'Stressed Emissions Costs',
          value: 'Stressed Emissions Costs',
        },
      ],
      aValueIsAlwaysSelected: true,
      currentValue: 'Disorderly Esg Data',
    },
    true,
    true,
  );
  const dataKey = useTitleBarSelect('key1') || 'Disorderly Esg Data';

  return (
    <GeneralComponentErrorShield
      dataObjects={[stressTestData]}
      customLoadingMessages={['Loading ESG Data...', '...']}
      customErrorMessage={'No ESG Data Found...'}
    >
      <Grid container spacing={2} style={{ padding: 8 }}>
        {dataKey === 'Stressed Emissions Costs' ? (
          <EmissionsCostsChart data={buildEmissionsChartData(stressTestData)} />
        ) : (
          <>
            <ScoreStressTestChart
              // data={environmentalData}
              data={buildChartData(
                stressTestData,
                'e_stress_test',
                dataKey === 'Disorderly Esg Data',
              )}
              chartTitle={
                dataKey === 'Disorderly Esg Data'
                  ? 'Disorderly Environmental Score Stress Test P/L'
                  : 'Environmental Score Stress Test P/L'
              }
            />
            <ScoreStressTestChart
              // data={socialData}
              data={buildChartData(
                stressTestData,
                's_stress_test',
                dataKey === 'Disorderly Esg Data',
              )}
              chartTitle={
                dataKey === 'Disorderly Esg Data'
                  ? 'Disorderly Social Score Stress Test P/L'
                  : 'Social Score Stress Test P/L'
              }
            />
            <ScoreStressTestChart
              // data={coproateData}
              data={buildChartData(
                stressTestData,
                'g_stress_test',
                dataKey === 'Disorderly Esg Data',
              )}
              chartTitle={
                dataKey === 'Disorderly Esg Data'
                  ? 'Disorderly Governance Score Stress Test P/L'
                  : 'Governance Score Stress Test P/L'
              }
            />
            <ScoreStressTestChart
              // data={esgData}
              data={buildChartData(
                stressTestData,
                'esg_stress_test',
                dataKey === 'Disorderly Esg Data',
              )}
              chartTitle={
                dataKey === 'Disorderly Esg Data'
                  ? 'Disorderly ESG Score Stress Test P/L'
                  : 'ESG Score Stress Test P/L'
              }
            />
          </>
        )}
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default ESGStressTests;
