import TopBar from '../topBar/TopBar';
import TitleBar from '../titleBar/Titlebar';
import RaptorSnackbar from '../../feedback/raptorSnackbar/Snackbar.component';
import Sidebar from '../sidebar/Sidebar';
import { zIndexes } from '../../../styling/zIndexes';
import { mainColors } from '../../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import { Outlet } from 'react-router-dom';
import AuthenticatedPage from '../../../routes/utils/AuthenticatedPage';

const useStyles = makeStyles({
  body: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: mainColors.background,
  },
  main: {
    height: 'calc(100% - 12rem)',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    padding: '1rem',
    zIndex: zIndexes.content,
  },
});

const GeneralLayout = () => {
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <TopBar />
      <TitleBar />
      <main className={classes.main}>
        <Sidebar />
        <div className={classes.content}>
          <AuthenticatedPage Component={Outlet} />
        </div>
      </main>
      <RaptorSnackbar />
    </div>
  );
};
export default GeneralLayout;
