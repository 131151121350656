import { FC } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import { makeUniqueDataKey } from '../../../../utilities/urlBuilder';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import MarketLiquidityRiskTable from './MarketLiquidityRIskTable.component';
import { Grid } from '@mui/material';

const MarketLiquidityRisk: FC<FundInfoComponentProps> = (props) => {
  const activeDate = props.positionDate;

  const urlForFetch = !activeDate
    ? `fund_liquidity_bid_ask_data/${props.fundId}`
    : `fund_liquidity_bid_ask_data/${props.fundId}/${activeDate}`;

  const marketLiquidityData = useFetchData({
    keyName: makeUniqueDataKey(props.fundId, 'market_liquidity', activeDate),
    url: urlForFetch,
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[marketLiquidityData]}
      customLoadingMessages={['Loading Market Liquidity Risk Data...']}
      customErrorMessage="No Data Available."
    >
      <Grid container>
        <MarketLiquidityRiskTable
          data={marketLiquidityData}
          title="Market Liquidity Risk"
        />
      </Grid>
    </GeneralComponentErrorShield>
  );
};

export default MarketLiquidityRisk;
