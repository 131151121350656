import React from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import BenchmarkBreakdownChart from './subComponents/SectorReturnAnalysisChart.component';
import BenchmarkBreakdownTable from './subComponents/SectorReturnAnalysisTable.component';
import {
  activeDateRangeEndSelector,
  activeDateRangeStartSelector,
} from '../../../../redux/pages/selectors';
import { useSelector } from 'react-redux';

const SubFundVsBenchmark: React.FC<FundInfoComponentProps> = ({ fundId }) => {
  const fromDate = useSelector(activeDateRangeStartSelector);
  const toDate = useSelector(activeDateRangeEndSelector);

  const PerformanceAttributionData = useFetchData({
    url: `/performance_attribution/${fundId}/${fromDate}/${toDate}`,
    keyName: `performance_attribution_${fundId}_${fromDate}_${toDate}`,
    makeFetch:
      fundId !== undefined &&
      fromDate !== null &&
      fromDate !== '' &&
      toDate !== null &&
      toDate !== '',
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[PerformanceAttributionData]}
      customLoadingMessages={[
        'Loading Performance Attribution Data',
        `${fromDate} to ${toDate}`,
        'Just a few more seconds...',
      ]}
      customErrorMessage="No Data Available."
    >
      <BenchmarkBreakdownChart data={PerformanceAttributionData} />
      <BenchmarkBreakdownTable data={PerformanceAttributionData} />
    </GeneralComponentErrorShield>
  );
};

export default SubFundVsBenchmark;
