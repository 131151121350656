import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  activeDateSelector,
  availableDatesSelector,
  createFundNameFromFundIdSelector,
  createSectionFundIdsSelector,
} from '../../../redux/pages/selectors';
import GeneralComponentErrorShield from '../../general/GeneralComponentErrorShield';
import { Grid, useTheme } from '@mui/material';
import DisplayAreaCenteredWrapper from '../utilities/displayAreaWrapper';

export interface GeneralFundInfoPageProps {
  section: string;
  pageId: string;
  fundId: string;
}

export interface FundInfoComponentProps {
  section: string;
  pageId: string;
  fundId: string;
  fundName: string;
  positionDate?: string;
  availableDates?: string[];
}

type GeneralFundInfoWrapperProps = {
  Component: React.ComponentType<any>;
  fundId: string;
  pageId: string;
};

export const GeneralFundInfoWrapper: FC<GeneralFundInfoWrapperProps> = ({
  Component,
  fundId,
  pageId,
  ...props
}) => {
  const { section } = useParams<{ section: string }>();

  const activeDate = useSelector(activeDateSelector);
  const availableDates = useSelector(availableDatesSelector);

  const fundIdsSelector = createSectionFundIdsSelector(section!);
  const fundIds = useSelector(fundIdsSelector);
  const fundName = useSelector(createFundNameFromFundIdSelector(fundId));
  const theme = useTheme();

  return (
    <Grid container spacing={2} style={{ padding: 8 }}>
      <GeneralComponentErrorShield dataObjects={[]}>
        {fundIds && fundIds.includes(fundId) ? (
          <Component
            {...props}
            section={section}
            pageId={pageId}
            fundId={fundId}
            fundName={fundName || 'Unknown Fund'}
            positionDate={activeDate || undefined}
            availableDates={availableDates || undefined}
          />
        ) : (
          <DisplayAreaCenteredWrapper>
            <h1 style={{ color: theme.palette.primary.main }}>
              Unknown fund selected
            </h1>
          </DisplayAreaCenteredWrapper>
        )}
      </GeneralComponentErrorShield>
    </Grid>
  );
};
