import React, { ReactElement, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { RaptorTheme, greys, mainColors } from '../../../../styling/theme';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';
import { Status } from '../../../../types/redux/data/dataTypes';
import {
  addCommasToNumbersAndRound,
  percentageToTwoDecimalPlaces,
} from '../../../../utilities/numberFormatters';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import useFetchData from '../../../../hooks/useFetchData';
import {
  activeDateRangeEndSelector,
  activeDateRangeStartSelector,
} from '../../../../redux/pages/selectors';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../../../utilities/requestClient';
import RaptorFileDownloadButton, {
  RaptorFileDownloadButtonStatus,
} from '../../../feedback/RaptorFileDownloadButton.component';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import { Button } from '@mui/material';

interface PageLinkButtonProps {
  status: number;
  text?: string;
  section?: string;
  page?: string;
  fundId?: string;
  passValue: number;
  alertValue: number;
}

function openInNewTab(url: string) {
  return async () => {
    const win = window.open(url, '_blank');
    if (win) {
      win.focus();
      return true;
    } else {
      return false;
    }
  };
}

interface ReportButtonProps {
  fundId: string;
  startDate: string;
  endDate: string;
  fundName: string;
}

function getGradinetColor(
  score: number,
  passValue: number,
  alertValue: number,
) {
  if (score >= passValue)
    return { main: mainColors.Pass, hover: mainColors.Pass_darker };
  else if (score < passValue && score > alertValue)
    return { main: mainColors.mainBlue_lighter, hover: mainColors.mainBlue };
  else return { main: mainColors.Alert, hover: mainColors.Alert_darker };
}

function getStatusColors(value: Status) {
  switch (value) {
    case Status.Pass:
      return { main: mainColors.Pass, hover: mainColors.Pass_darker };
    case Status.Alert:
    case Status.PassAlert:
      return { main: mainColors.Alert, hover: mainColors.Alert_darker };
    case Status.Fail:
      return { main: mainColors.Fail, hover: mainColors.Fail_darker };
    case Status.NA:
    default:
      return { main: greys.grey400, hover: greys.grey400 };
  }
}

interface StyleProps {
  mainColor: string;
  mainColorHover: string;
}

const useStyles = makeStyles<RaptorTheme, StyleProps>((theme) => ({
  PageLinkButtonRoot: {
    backgroundColor: (props) => props.mainColor,
    color: 'white',
    width: '100%',
    maxWidth: '20rem',
    '&:hover': {
      backgroundColor: (props: any) => props.mainColorHover,
    },
  },
  PageLinkButtonRootDisabled: {
    backgroundColor: (props) => greys.grey400,
    color: 'black',
    width: '100%',
    maxWidth: '20rem',
  },
  statusContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nonLinkStatusBox: {
    backgroundColor: (props) => props.mainColor,
    color: 'white',
    width: '100%',
    maxWidth: '20rem',
    borderRadius: '0.4rem',
    padding: '0.8rem',
    userSelect: 'none',
  },
}));

export function PAPageLinkButton({
  status,
  section,
  page,
  fundId,
  passValue,
  alertValue,
}: React.PropsWithChildren<PageLinkButtonProps>): ReactElement {
  const handleClick = () => {
    //TODO manage what clicking on this button does
    //   if (props.additionalHandler) props.additionalHandler();
  };
  const color = getGradinetColor(status, passValue, alertValue);
  const classes = useStyles({
    mainColor: color.main,
    mainColorHover: color.hover,
  });

  return section && page ? (
    <Link to={`/${section}/${page}${fundId ? `?fundId=${fundId}` : ``}`}>
      <Button onClick={handleClick} className={classes.PageLinkButtonRoot}>
        {percentageToTwoDecimalPlaces(status)}
      </Button>
    </Link>
  ) : (
    <div className={classes.statusContainer}>
      <div className={classes.nonLinkStatusBox}>
        {percentageToTwoDecimalPlaces(status)}
      </div>
    </div>
  );
}

const PAReportButton: React.FC<ReportButtonProps> = ({
  fundId,
  startDate,
  endDate,
  fundName,
}) => {
  const [status, setStatus] = useState<RaptorFileDownloadButtonStatus>('idle');

  function generateReport() {
    const openReport = openInNewTab(
      `${BASE_URL}generate_performance_attribution_report/${fundId}/${startDate}/${endDate}`,
    );
    openReport();
    setStatus('idle');
  }

  return (
    <div
      style={{
        display: 'flex',
        margin: 'auto',
        maxWidth: '20rem',
      }}
      onClick={() => {
        if (status === 'idle') {
          setStatus('loading');
          generateReport();
        }
      }}
    >
      <RaptorFileDownloadButton status={status} />
    </div>
  );
};

export interface PaOverviewTableData {
  internalFundName: string;
  fundName: string;
  positionDate: string;
  share_class_isin: string;
  currency: string;
  netAssetValue: number;
  oneDayChange: number;
  weekToDate: number;
  monthToDate: number;
  quarterToDate: number;
  index: number;
}

function buildPAOverviewDetailColumns(
  startDate: string,
  endDate: string,
  clientName: string | null,
): CustomColumn<PaOverviewTableData>[] {
  const detailColumns: CustomColumn<PaOverviewTableData>[] = [
    {
      title: 'Internal Fund Name',
      field: 'internalFundName',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData) =>
        clientName?.includes('mersenne')
          ? `Fund${rowData.index + 1}`
          : rowData.internalFundName,
    },
    {
      title: 'Fund Name',
      field: 'fundName',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Position Date',
      field: 'positionDate',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Report',
      field: 'positionDate',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData: PaOverviewTableData) => (
        <PAReportButton
          fundId={rowData.internalFundName}
          startDate={startDate}
          endDate={endDate}
          fundName={rowData.fundName}
        />
      ),
    },
    {
      title: 'ISIN',
      field: 'share_class_isin',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Currency',
      field: 'currency',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
    },
    {
      title: 'Net Asset Value',
      field: 'netAssetValue',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData) => addCommasToNumbersAndRound(rowData.netAssetValue),
    },
    {
      title: 'One Day Change',
      field: 'oneDayChange',
      width: '10%',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData: PaOverviewTableData) => (
        <PAPageLinkButton
          status={rowData.oneDayChange}
          section="pa"
          page="sub-fund-performance"
          fundId={rowData.internalFundName}
          passValue={0}
          alertValue={-0.02}
        />
      ),
    },
    {
      title: 'Week To Date',
      field: 'weekToDate',
      width: '10%',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData: PaOverviewTableData) => (
        <PAPageLinkButton
          status={rowData.weekToDate}
          section="pa"
          page="sub-fund-performance"
          fundId={rowData.internalFundName}
          passValue={0}
          alertValue={-0.05}
        />
      ),
    },
    {
      title: 'Month To Date',
      field: 'monthToDate',
      width: '10%',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData: PaOverviewTableData) => (
        <PAPageLinkButton
          status={rowData.monthToDate}
          section="pa"
          page="sub-fund-performance"
          fundId={rowData.internalFundName}
          passValue={0}
          alertValue={-0.09}
        />
      ),
    },
    {
      title: 'Quarter To Date',
      field: 'quarterToDate',
      width: '10%',
      cellStyle: { textAlign: 'center' },
      headerStyle: { textAlign: 'center' },
      render: (rowData: PaOverviewTableData) => (
        <PAPageLinkButton
          status={rowData.quarterToDate}
          section="pa"
          page="sub-fund-performance"
          fundId={rowData.internalFundName}
          passValue={0}
          alertValue={-0.15}
        />
      ),
    },
  ];
  return detailColumns;
}

function buildPaOverviewTableData(data: any): PaOverviewTableData[] {
  if (!data) return [];
  if (!('data' in data)) return [];
  if (!data.data.length) return [];
  const paData = data.data[0];
  const tableData: PaOverviewTableData[] = [];
  paData.forEach((fund: any, index: number) => {
    tableData.push({
      internalFundName: fund.internal_fund_name,
      fundName: fund.fund_name,
      positionDate: fund.selected_position_date,
      share_class_isin: fund.share_class_isin,
      currency: fund.fund_currency,
      netAssetValue: fund.fund_nav,
      oneDayChange: fund.one_day_change,
      weekToDate: fund.week_to_date,
      monthToDate: fund.month_to_date,
      quarterToDate: fund.quarter_to_date,
      index,
    });
  });

  return tableData;
}

const PaOverview: React.FC<GeneralOverviewPageRenderedComponentProps> = () => {
  const fromDate = useSelector(activeDateRangeStartSelector);
  const toDate = useSelector(activeDateRangeEndSelector);

  const overviewData = useFetchData({
    url: `performance_attribution_overview/${toDate}/`,
    keyName: `pa_overview_data_${toDate}`,
    makeFetch: toDate ? true : false,
  });

  const tableHeight = 'calc(100vh - 25rem)';
  const dataPreparedForTable = buildPaOverviewTableData(overviewData);
  const clientName = useSelector(clientNameSelector);
  const detailColumns = buildPAOverviewDetailColumns(
    fromDate,
    toDate,
    clientName,
  );

  return (
    <GeneralComponentErrorShield dataObjects={[overviewData]}>
      <GridItem card xs={12}>
        <CustomTable<PaOverviewTableData>
          showToolbar
          loadingHeight={tableHeight}
          title="PA Overview Data"
          columns={detailColumns}
          data={dataPreparedForTable}
          options={{
            paging: false,
            search: true,
            exportButton: true,
            rowStyle: {
              padding: '1rem',
            },
          }}
        />
      </GridItem>
    </GeneralComponentErrorShield>
  );
};

export default PaOverview;
