import { FC } from 'react';
import OverallStatusPage from '../../sharedComponents/overallStatusPage/component';
import { useSelector } from 'react-redux';
import { activeDateSelector } from '../../../../redux/pages/selectors';

const ProspectusRestrictionsOverview: FC = () => {
  const activeDate = useSelector(activeDateSelector);

  return (
    <OverallStatusPage
      overviewDataPageKey="Risk_Compliance"
      positionDate={activeDate || undefined}
    />
  );
};

export default ProspectusRestrictionsOverview;