import { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  configNameSelector,
  isLoggedInSelector,
} from '../redux/auth/selectors';
import { Navigate, Outlet } from 'react-router-dom';
import axios from 'axios';
import { fetchClientConfig } from '../redux/pages/actions';
import GeneralComponentErrorShield from '../components/general/GeneralComponentErrorShield';
import { clientConfigurationSelector } from '../redux/pages/selectors';

const ProtectedRoutes: FunctionComponent = () => {
  const isAuthenticated = useSelector(isLoggedInSelector);
  const dispatch = useDispatch();
  const configName = useSelector(configNameSelector);
  const clientConfig = useSelector(clientConfigurationSelector);
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  useEffect(() => {
    if (configName) {
      dispatch(fetchClientConfig(configName, source));
    }
  }, [configName]);

  const configError = `No client configuration for ${configName} was detected. Please contact the RiskSystem administrator.`;

  return isAuthenticated ? (
    <GeneralComponentErrorShield
      dataObjects={clientConfig}
      customErrorMessage={configError}
      customLoadingMessages={['Loading your Raptor configuration...']}
    >
      <Outlet />
    </GeneralComponentErrorShield>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default ProtectedRoutes;
