import React from 'react';
import OverallStatusPage from '../../sharedComponents/overallStatusPage/component';

const UcitsLawOverview: React.FC = (props: any) => {
  return (
    <OverallStatusPage
      overviewDataPageKey="UCITS"
      positionDate={props.positionDate}
    />
  );
};

export default UcitsLawOverview;
