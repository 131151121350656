import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAdditionalTitleBarDatePicker } from '../../../../hooks/useAdditionalTitleBarComponents';
import useFetchData from '../../../../hooks/useFetchData';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import {
  createActiveFundSelectorBySection,
  createSpecificTitleBarDatePickerSelector,
} from '../../../../redux/pages/selectors';
import { DatePicker, Select } from '../../../../types/redux/pages/PagesStore';
import {
  formatDateForCheckingState,
  todayFormatted,
} from '../../../../utilities/dateFormatters';
import {
  getMostRecentWeekday,
  getWeekdayXMonthsAgo,
} from '../../../../utilities/dateUtilities';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { GeneralFundInfoPageProps } from '../../../layout/general/GeneralFundInfoWrapper';
import GridItem from '../../../layout/GridComponents/GridItem';
import DisplayAreaCenteredWrapper from '../../../layout/utilities/displayAreaWrapper';
import { FundFailTable } from './FailsPage.data';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
} from '../../../../redux/pages/actions';
import {
  APPEND_NEW_SELECT_TO_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
} from '../../../../types/redux/pages/pageTypes';

/**
 * Hook to make use of a start date and end date in the titlebar
 * @returns either undefined for both dates or the value of both
 */
function useDatePickers() {
  const startDateWeekday = getWeekdayXMonthsAgo(1);
  const endDateWeekday = getMostRecentWeekday();

  const startDatePicker: DatePicker = {
    titleBarKey: 'startDate',
    titleBarTitle: 'Select Start Date: ',
    currentValue: dayjs(startDateWeekday).format('YYYY-MM-DD'),
    displayOnOverviewPage: false,
  };

  const endDatePicker: DatePicker = {
    titleBarKey: 'endDate',
    titleBarTitle: 'Select End Date: ',
    currentValue: dayjs(endDateWeekday).format('YYYY-MM-DD'),
    displayOnOverviewPage: false,
  };

  useAdditionalTitleBarDatePicker(startDatePicker);
  useAdditionalTitleBarDatePicker(endDatePicker);

  const startDate = useSelector(
    createSpecificTitleBarDatePickerSelector('startDate'),
  );
  const endDate = useSelector(
    createSpecificTitleBarDatePickerSelector('endDate'),
  );
  return {
    startDate: dayjs(startDate[0]?.currentValue).toDate(),
    endDate: dayjs(endDate[0]?.currentValue).toDate(),
  };
}

function useFailsDataFetch(
  startDate: Date | undefined,
  endDate: Date | undefined,
  area: 'ucits' | 'prospectus',
  fundId?: string,
) {
  const startDateForFetch = startDate
    ? formatDateForCheckingState(startDate)
    : todayFormatted();
  const endDateForFetch = endDate
    ? formatDateForCheckingState(endDate)
    : todayFormatted();

  const dataFetch = useFetchData({
    keyName: `${area}_${startDateForFetch}_${endDateForFetch}_all_fails${
      fundId ? `_${fundId}` : ''
    }`,
    url: `/${area}_fail_overview/${startDateForFetch}/${endDateForFetch}${
      fundId ? `/${fundId}` : ''
    }`,
    makeFetch: startDate !== undefined && endDate !== undefined,
  });
  return dataFetch;
}

export function FailsPageWithoutFund() {
  return (
    <DisplayAreaCenteredWrapper>
      <Typography variant="h2">Please Select a Fund</Typography>
    </DisplayAreaCenteredWrapper>
  );
}

// this is the fails page if we have a fund Id
function FailsPage({
  fundId,
  section,
}: GeneralFundInfoPageProps): ReactElement {
  const dispatch = useDispatch();

  // Add the select components to the title bar
  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        titleBarTitle: 'Show Fails From: ',
        titleBarKey: 'selectFails',
        values: [
          {
            label: 'UCITS Law',
            value: 'ucits',
          },
          {
            label: 'UCITS Prospectus Restrictions',
            value: 'prospectus',
          },
        ],
        displayOnOverviewPage: true,
        aValueIsAlwaysSelected: true,
        currentValue: 'ucits',
      },
    ];

    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR,
      ),
    );

    return () => {
      dispatch(
        removeTitleBarComponent('selectFails', REMOVE_SELECT_FROM_TITLEBAR),
      );
    };
  }, []);

  const { startDate, endDate } = useDatePickers();
  const details = useSelector(createActiveFundSelectorBySection(section));
  const ucitsFundFails = useFailsDataFetch(startDate, endDate, 'ucits', fundId);
  const prospectusFundFails = useFailsDataFetch(
    startDate,
    endDate,
    'prospectus',
    fundId,
  );
  const [failsToRender, setFailsToRender] = useState<any>({
    ucits: [],
    prospectus: [],
  });
  const failsSelected = useTitleBarSelect('selectFails');

  useEffect(() => {
    if (
      ucitsFundFails &&
      prospectusFundFails &&
      !ucitsFundFails.isFetching &&
      !prospectusFundFails.isFetching &&
      !ucitsFundFails.error &&
      !prospectusFundFails.error
    ) {
      setFailsToRender({
        ucits: ucitsFundFails.data,
        prospectus: prospectusFundFails.data,
      });
    }
  }, [ucitsFundFails, prospectusFundFails]);

  return (
    <GeneralComponentErrorShield
      dataObjects={[ucitsFundFails, prospectusFundFails]}
    >
      {failsSelected && failsToRender[failsSelected!].length > 0 && (
        <GridItem xs={12}>
          <Typography variant="h2" style={{ marginBottom: '.5rem' }}>
            {failsSelected === 'ucits'
              ? 'UCITS Law'
              : 'Prospectus Restrictions'}
          </Typography>
          <FundFailTable
            subject="ucits_ucits-law"
            startDate={formatDateForCheckingState(dayjs(startDate).toDate())!}
            endDate={formatDateForCheckingState(dayjs(endDate).toDate())!}
            fundName={details?.name || 'Unknown Fund'}
            fundId={fundId}
            failsData={failsToRender[failsSelected!][0][0]}
          />
        </GridItem>
      )}
    </GeneralComponentErrorShield>
  );
}

export default FailsPage;
