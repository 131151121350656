import React, { FC } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import {
  formatFundUrl,
  makeUniqueDataKey,
} from '../../../../utilities/urlBuilder';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import { LiquidityUrls } from '../fundAssetLiquidationTime/component';
import InvestorBased from './investorBased/investorBased.component';
import MarketBasedTable from './marketBasedAndMaximum/marketBased.component';

const LiquidationRiskOverview: FC<FundInfoComponentProps> = (props) => {
  const { fundId, positionDate, section } = props;
  const liquidityRiskOverviewData = useFetchData({
    url: formatFundUrl(
      LiquidityUrls.FETCH_FUND_LIQUIDITY_RISK_OVERVIEW,
      fundId,
      positionDate,
    ),
    keyName: makeUniqueDataKey(fundId, 'liquidationRiskOverview', positionDate),
    makeFetch: true,
  });
  return (
    <GeneralComponentErrorShield dataObjects={[liquidityRiskOverviewData]}>
      <MarketBasedTable inputData={liquidityRiskOverviewData} />
      <InvestorBased
        section={section}
        investorData={liquidityRiskOverviewData}
      />
    </GeneralComponentErrorShield>
  );
};

export default LiquidationRiskOverview;
