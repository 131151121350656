import React from 'react';
import { useSelector } from 'react-redux';
import useFetchData from '../../../../hooks/useFetchData';
import { clientNameSelector } from '../../../../redux/auth/selectors';
import { GeneralOverviewPageRenderedComponentProps } from '../../../../types/components/componentTypes';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import { createSectionByIdSelector } from '../../../../redux/pages/selectors';
import { Section } from '../../../../types/server-data/ClientConfig';
import {
  createGeneralTableData,
  getGeneralOverviewColumns,
  OverviewColumnFields,
} from './overview-columns';
import { Status } from '../../../../types/redux/data/dataTypes';
import OverviewSecondLevelGraphs from './OverviewSecondLevelGraphs.component';
import { mapStatusToColor } from '../../../../utilities/colorUtilities';
import NoDataMessage from '../../../feedback/NoDataMessage.component';
import { specifyStatus } from '../../../../utilities/generalMappings';
import { CustomColumn } from '../../../../types/components/tables/tableTypes';

type FundType = 'var' | 'commitment' | 'both';

export interface GeneralOverviewTableData {
  internalFundName: string;
  fundName: string;
  reports: string[];
  subFundRiskReports: string[];
  positionDate: string;
  calculationDate: string;
  currency: string;
  netAssetValue: number;
  commitmentValue: number;
  commitmentStatus: Status;
  valueAtRiskStatus: Status;
  valueAtRiskValue: number;
  varFund: string;
  varDay: string;
  ucitsLaw: Status;
  prospectusStatus: Status;
  countryExposure: any;
  positionExposure: any;
  index: number;
  derogationMessage?: string;
  timeSeriesData: any | null;
  timeSeriesStatus: any | null;
  notes: boolean;
  leverage: Status;
  fundType: FundType;
  act40Law?: Status;
  riaifLaw?: Status;
  qiaifLaw?: Status;
  nursLaw?: Status;
  fivgLaw?: Status;
  partiiLaw?: Status;
  cssfRiskSpreading?: Status;
  // [key: string]: any; // Any additional fields added from BE
}

// This function handles the fetching of the data needed for the overview page
const fetchOverviewData = (
  sectionDetails: Section | null,
  positionDate: string | undefined,
  clientName: string | null,
  fundTypes: string | undefined,
) => {
  const schemas = sectionDetails?.fundTypes.length
    ? `?schemas=${sectionDetails?.fundTypes}`
    : '';

  const reportsListResponse = useFetchData({
    url: `reports_list` + schemas,
    keyName: `${fundTypes}_overview_reports_list`,
    makeFetch: true,
  });

  const overviewResponse = useFetchData({
    url: `schema_overview_monitor/${positionDate}` + schemas,
    keyName: `${fundTypes}_overview_data_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  const subFundRiskReportsListResponse = useFetchData({
    url: 'reports_list/sub_fund_risk_report' + schemas,
    keyName: `${fundTypes}_sub_fund_risk_report`,
    makeFetch: clientName?.includes('pure') || false,
  });

  return {
    reportsListResponse,
    overviewResponse,
    subFundRiskReportsListResponse,
  };
};

// Temporary function to get the columns for the overview table
// Eventually this will be moved to the backend
function getOverviewColumnsFields(
  clientName: string | null,
  sectionID?: string,
): OverviewColumnFields[] | null {
  // ----------------- CLIENT SPECIFIC COLUMN CONFIGURATIONS -----------------

  if (clientName === 'pure') {
    if (sectionID === 'ucits') {
      return [
        'index',
        'internalFundName',
        'fundName',
        'reports',
        'subFundRiskReports',
        'positionDate',
        'calculationDate',
        'currency',
        'netAssetValue',
        'commitmentValue',
        'valueAtRiskValue',
        'ucitsLaw',
        'prospectusStatus',
        'notes',
      ];
    } else if (sectionID === 'fivg') {
      return [
        'index',
        'internalFundName',
        'fundName',
        'reports',
        'subFundRiskReports',
        'positionDate',
        'calculationDate',
        'currency',
        'netAssetValue',
        'commitmentValue',
        'valueAtRiskValue',
        'fivgLaw',
        'prospectusStatus',
        'notes',
      ];
    } else if (sectionID === 'partii') {
      return [
        'index',
        'internalFundName',
        'fundName',
        'reports',
        'subFundRiskReports',
        'positionDate',
        'calculationDate',
        'currency',
        'netAssetValue',
        'commitmentValue',
        'valueAtRiskValue',
        'partiiLaw',
        'prospectusStatus',
        'notes',
      ];
    } else if (sectionID === 'sif-raif') {
      return [
        'index',
        'internalFundName',
        'fundName',
        'reports',
        'subFundRiskReports',
        'positionDate',
        'currency',
        'netAssetValue',
        'leverage',
        'cssfRiskSpreading',
        'prospectusStatus',
        'notes',
      ];
    }
  }

  // ----------------- DEFAULT COLUMN CONFIGURATIONS -----------------

  if (sectionID === 'ucits') {
    return [
      'index',
      'internalFundName',
      'fundName',
      'reports',
      'positionDate',
      'calculationDate',
      'currency',
      'netAssetValue',
      'commitmentValue',
      'valueAtRiskValue',
      'ucitsLaw',
      'prospectusStatus',
      'notes',
    ];
  } else if (sectionID === 'qiaif') {
    return [
      'index',
      'internalFundName',
      'fundName',
      'reports',
      'positionDate',
      'calculationDate',
      'currency',
      'netAssetValue',
      'leverage',
      'valueAtRiskValue',
      'qiaifLaw',
      'prospectusStatus',
      'notes',
    ];
  } else if (sectionID === 'riaif') {
    return [
      'index',
      'internalFundName',
      'fundName',
      'reports',
      'positionDate',
      'calculationDate',
      'currency',
      'netAssetValue',
      'leverage',
      'valueAtRiskValue',
      'riaifLaw',
      'prospectusStatus',
      'notes',
    ];
  } else if (sectionID === 'nurs') {
    return [
      'index',
      'internalFundName',
      'fundName',
      'reports',
      'positionDate',
      'calculationDate',
      'currency',
      'netAssetValue',
      'commitmentValue',
      'valueAtRiskValue',
      'nursLaw',
      'prospectusStatus',
      'notes',
    ];
  } else if (sectionID === 'act40') {
    return [
      'index',
      'internalFundName',
      'fundName',
      'reports',
      'positionDate',
      'calculationDate',
      'currency',
      'netAssetValue',
      'commitmentValue',
      'valueAtRiskValue',
      'act40Law',
      'prospectusStatus',
      'notes',
    ];
  } else if (sectionID === 'fivg') {
    return [
      'index',
      'internalFundName',
      'fundName',
      'reports',
      'positionDate',
      'calculationDate',
      'currency',
      'netAssetValue',
      'commitmentValue',
      'valueAtRiskValue',
      'fivgLaw',
      'prospectusStatus',
      'notes',
    ];
  } else if (sectionID === 'trust') {
    return [
      'index',
      'internalFundName',
      'fundName',
      'reports',
      'positionDate',
      'calculationDate',
      'currency',
      'netAssetValue',
      'commitmentValue',
      'valueAtRiskValue',
      'prospectusStatus',
      'notes',
    ];
  } else if (sectionID === 'sif-raif') {
    return [
      'index',
      'internalFundName',
      'fundName',
      'reports',
      'positionDate',
      'currency',
      'netAssetValue',
      'leverage',
      'cssfRiskSpreading',
      'prospectusStatus',
      'notes',
    ];
  } else if (sectionID === 'partii') {
    return [
      'index',
      'internalFundName',
      'fundName',
      'reports',
      'positionDate',
      'calculationDate',
      'currency',
      'netAssetValue',
      'commitmentValue',
      'valueAtRiskValue',
      'partiiLaw',
      'prospectusStatus',
      'notes',
    ];
  }

  return null;
}

const Overview: React.FC<GeneralOverviewPageRenderedComponentProps> = (
  props,
) => {
  const sectionDetails = useSelector(createSectionByIdSelector(props.section));
  const fundTypes = sectionDetails?.fundTypes;
  const clientName = useSelector(clientNameSelector);

  // Fetch all data needed for the table
  const {
    reportsListResponse,
    overviewResponse,
    subFundRiskReportsListResponse,
  } = fetchOverviewData(
    sectionDetails,
    props.positionDate,
    clientName,
    fundTypes,
  );

  // Handle formatting of the data for the table
  let overviewData: any = [];
  if (overviewResponse && overviewResponse.data.length) {
    overviewData = overviewResponse.data[0];
  }

  const reportsList =
    reportsListResponse && reportsListResponse.data.length
      ? reportsListResponse.data
      : null;

  const subFundRiskReportsList =
    subFundRiskReportsListResponse && subFundRiskReportsListResponse.data.length
      ? subFundRiskReportsListResponse.data
      : null;

  // column fields, given the clientName and sectionID
  // eventually this will be recieved fromn backend configs
  // When moving it to the backend, we should also cleanup the field names to make them more consistent (ie. ucitsLaw, riaifLaw, etc should all just be law)
  const columnsFields = getOverviewColumnsFields(
    clientName,
    sectionDetails?.sectionId,
  );

  // Takes the clientName - additional props - and the columns fields to generate the columns
  const columns = getGeneralOverviewColumns(
    clientName,
    columnsFields,
    props.section,
  );

  // Prepare the data for the table
  const dataPreparedForTable = createGeneralTableData(
    overviewData,
    reportsList,
    subFundRiskReportsList,
    clientName,
    props.section,
  );

  // USE THIS FOR changing heatmap to button -> https://www.gitmemory.com/issue/mbrn/material-table/1340/555845261
  const tableHeight = 'calc(100vh - 25rem)';

  const overviewCSVFields = columnsFields
    ? columnsFields.filter((field) => field !== 'reports' && field !== 'index')
    : [];

  const generateFieldMap = (
    columns: CustomColumn<GeneralOverviewTableData>[],
  ) => {
    return columns
      .filter((col) => col.field !== 'reports' && col.field !== 'index')
      .map((column) => {
        const returnObj = {
          key: column.field as string,
          label: column.title as string,
        };
        return returnObj;
      });
  };

  const csvFieldMap = generateFieldMap(columns);

  return overviewData ? (
    <GeneralComponentErrorShield
      dataObjects={[overviewResponse, reportsListResponse]}
    >
      {columnsFields ? (
        <GridItem card xs={12}>
          <CustomTable<GeneralOverviewTableData>
            csvFields={overviewCSVFields}
            fieldsMap={csvFieldMap}
            showToolbar
            loadingHeight={tableHeight}
            title={`${sectionDetails?.sectionTitle.toUpperCase()} Overview Data`}
            columns={columns}
            data={dataPreparedForTable}
            detailPanel={[
              {
                disabled: false,
                render: (rowData: GeneralOverviewTableData) => {
                  return (
                    <OverviewSecondLevelGraphs
                      fundName={rowData.internalFundName}
                      positionDate={rowData.positionDate}
                      varFund={rowData.varFund === 'yes'}
                      passValue={mapStatusToColor(
                        specifyStatus(rowData.timeSeriesStatus),
                      )}
                    />
                  );
                },
              },
            ]}
            options={{
              paging: false,
              pageSize: 10,
              pageSizeOptions: [10, 20, dataPreparedForTable.length],
              search: true,
              exportButton: true,
              rowStyle: {
                padding: '1rem',
              },
              raptorExport: true,
            }}
          />
        </GridItem>
      ) : (
        <NoDataMessage
          message={`No configuration found for the section ${sectionDetails?.sectionId}`}
        />
      )}
    </GeneralComponentErrorShield>
  ) : null;
};

export default Overview;
