import React, { ReactElement } from 'react';
import { zIndexes } from '../../../styling/zIndexes';
import { RaptorTheme, mainColors } from '../../../styling/theme';
import { NavLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import TokenService from '../../../services/token-service';

const useStyles = makeStyles<RaptorTheme>((theme) => ({
  sidebarLeftRoot: {
    width: '30rem',
    zIndex: zIndexes.sideBar,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '1.5rem',
    boxShadow: theme.shadows[1],
    height: 'calc(100vh)',
    padding: '1rem',
    color: mainColors.mainBlue,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    width: '100%',
  },
  sectionHeading: {
    all: 'unset',
    fontSize: '2rem',
    fontWeight: 600,
    padding: '0.5rem 1rem',
    borderRadius: '0.4rem',
  },
  sectionItem: {
    all: 'unset',
    fontSize: '1.6rem',
    fontWeight: 400,
    marginLeft: '2rem',
    padding: '0.5rem 1rem',
    cursor: 'pointer',
    borderRadius: '0.4rem',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  active: {
    backgroundColor: theme.palette.grey[100],
  },
  divider: {
    width: '100%',
    borderTop: `1px solid ${mainColors.mainBlue}`,
  },
  sideBarTitle: {
    fontSize: '3rem',
    fontWeight: 600,
    color: mainColors.mainBlue,
    padding: '0 1rem',
  },
}));

function SettingsMenu(): ReactElement {
  const classes = useStyles();

  const userDetails = TokenService.getUser();

  return (
    <div className={classes.sidebarLeftRoot}>
      <div className={classes.sideBarTitle}>Settings</div>
      <div className={classes.divider}></div>
      <div className={classes.section}>
        <div className={classes.sectionHeading}>Account</div>
        <NavLink
          to="/settings/my-account"
          className={({ isActive }) =>
            isActive
              ? `${classes.sectionItem} ${classes.active}`
              : classes.sectionItem
          }
        >
          My Account
        </NavLink>
        {userDetails && userDetails.user_type === 'external_administrator' ? (
          <NavLink
            to="/settings/manage-accounts"
            className={({ isActive }) =>
              isActive
                ? `${classes.sectionItem} ${classes.active}`
                : classes.sectionItem
            }
          >
            Manage Accounts
          </NavLink>
        ) : null}
        {userDetails && userDetails.user_type === 'external_administrator' ? (
          <NavLink
            to="/settings/security-settings"
            className={({ isActive }) =>
              isActive
                ? `${classes.sectionItem} ${classes.active}`
                : classes.sectionItem
            }
          >
            Security Settings
          </NavLink>
        ) : null}
      </div>
    </div>
  );
}

export default SettingsMenu;
