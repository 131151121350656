import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFetchData from '../../../../hooks/useFetchData';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import {
  createSectionByIdSelector,
  createSpecificFundNameAndIdSelectorByFundType,
} from '../../../../redux/pages/selectors';
import { DataObject } from '../../../../types/redux/data/dataTypes';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { GeneralFundInfoPageProps } from '../../../layout/general/GeneralFundInfoWrapper';
import GridItem from '../../../layout/GridComponents/GridItem';
import CustomTable from '../../../tables/CustomTable';
import {
  ShareClassHedgeTableData,
  generateShareClassHedgeData,
  generateShareClassTableColumns,
  getNavDate,
} from './ShareClassHedgeData.data';
import { useFilterFunds } from './ShareClassHedgeData.overview';
import { Select } from '../../../../types/redux/pages/PagesStore';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
} from '../../../../redux/pages/actions';
import {
  APPEND_NEW_SELECT_TO_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
} from '../../../../types/redux/pages/pageTypes';

const ShareClassTable = ({
  data,
  title,
}: {
  data: DataObject;
  title: string;
}) => {
  const generatedData = generateShareClassHedgeData(data.data[0]);
  const navDate = getNavDate(data);
  return (
    <GridItem xs={12} card>
      <CustomTable<ShareClassHedgeTableData>
        title={title + ' - NAV Date: ' + navDate}
        data={generatedData}
        columns={generateShareClassTableColumns(
          data.data[0].base_currency || '-',
        )}
        options={{ paging: false, exportButton: true }}
        showToolbar
      />
    </GridItem>
  );
};

function ShareClassHedgeData({
  fundId,
  section,
}: GeneralFundInfoPageProps): ReactElement {
  const dispatch = useDispatch();

  // Add the select components to the title bar
  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        titleBarTitle: 'Nav Stress: ',
        titleBarKey: 'navStress',
        values: [
          {
            label: '10%',
            value: '10',
          },
          {
            label: '5%',
            value: '5',
          },
          {
            label: '2%',
            value: '2',
          },

          {
            label: '1%',
            value: '1',
          },
          {
            label: 'None applied',
            value: '0',
          },
          {
            label: '-1%',
            value: '-1',
          },
          {
            label: '-2%',
            value: '-2',
          },
          {
            label: '-5%',
            value: '-5',
          },
          {
            label: '-10%',
            value: '-10',
          },
        ],
        displayOnOverviewPage: true,
        aValueIsAlwaysSelected: true,
        currentValue: '0',
      },
      {
        titleBarTitle: 'FX Stress: ',
        titleBarKey: 'fxStress',
        values: [
          {
            label: '10%',
            value: '10',
          },
          {
            label: '5%',
            value: '5',
          },
          {
            label: '2%',
            value: '2',
          },

          {
            label: '1%',
            value: '1',
          },
          {
            label: 'None applied',
            value: '0',
          },
          {
            label: '-1%',
            value: '-1',
          },
          {
            label: '-2%',
            value: '-2',
          },
          {
            label: '-5%',
            value: '-5',
          },
          {
            label: '-10%',
            value: '-10',
          },
        ],
        displayOnOverviewPage: true,
        aValueIsAlwaysSelected: true,
        currentValue: '0',
      },
    ];

    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR,
      ),
    );

    return () => {
      dispatch(
        removeTitleBarComponent('navStress', REMOVE_SELECT_FROM_TITLEBAR),
      );
      dispatch(
        removeTitleBarComponent('fxStress', REMOVE_SELECT_FROM_TITLEBAR),
      );
    };
  }, []);

  const navStress = useTitleBarSelect('navStress');
  const fxStress = useTitleBarSelect('fxStress');

  const sectionDetailsSelector = createSectionByIdSelector(section);
  const sectionDetails = useSelector(sectionDetailsSelector);
  const fundDetailsSelector = createSpecificFundNameAndIdSelectorByFundType(
    sectionDetails?.fundTypes || 'UCITS',
    fundId,
    sectionDetails?.rsResources || 'RISK,LIQUIDITY',
  );

  const fundDetails = useSelector(fundDetailsSelector);
  const keyForFetch = `msc_share_class_fx_hedge_monitor_rt_agg/${fundId}/${navStress}/${fxStress}`;
  const shareClassData = useFetchData({
    url: keyForFetch,
    keyName: keyForFetch,
    makeFetch: true,
  });

  // this filtering is for coutts - can adapt it based on different clients
  useFilterFunds(
    [
      'coutts305',
      'coutts306',
      'coutts307',
      'bflexion101',
      'nanjia_001fx',
      'nanjia_002fx',
      'nanjia_003fx',
    ],
    sectionDetails?.fundTypes as string,
    sectionDetails?.rsResources as string,
  );

  return (
    <GeneralComponentErrorShield dataObjects={[shareClassData]}>
      <ShareClassTable title={fundDetails?.name || ''} data={shareClassData} />
    </GeneralComponentErrorShield>
  );
}

export default ShareClassHedgeData;
