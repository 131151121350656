import React from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import RelativeRiskTable from './subComponents/RelativeRiskTable.component';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import RelativeRiskChart from './subComponents/RelativeRiskChart.component';

const RelativeRisk: React.FC<FundInfoComponentProps> = ({
  positionDate,
  fundId,
}) => {
  const relativeRiskData = useFetchData({
    url: `/relative_risk_overview/${fundId}/${positionDate}`,
    keyName: `relative_risk_overview_${fundId}_${positionDate}`,
    makeFetch: positionDate ? true : false,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[relativeRiskData]}
      customLoadingMessages={['Loading Relative Value At Risk Data...']}
      customErrorMessage="No Data Available."
    >
      <RelativeRiskChart data={relativeRiskData} />
      <RelativeRiskTable data={relativeRiskData} />
    </GeneralComponentErrorShield>
  );
};

export default RelativeRisk;
