// This component will esentially behave similar to the standard pfd export button but will allow specific elements
// of a page to be exported rather than attempting to convert the whole page to a pdf file which in some instances
// does not work correctly
import React from 'react';
import { useSelector } from 'react-redux';
import { clientNameSelector, configNameSelector } from '../../../../../redux/auth/selectors';
import {
  activeDateSelector,
  activePageSelector,
  activeSectionSelector,
  createActiveFundSelectorBySection,
  createPageDetailsSelector,
} from '../../../../../redux/pages/selectors';
import { formatDateForCheckingState } from '../../../../../utilities/dateFormatters';
import { PdfGenerator } from '../pdfExportButton/PdfExportButton';
import html2canvas from 'html2canvas';
import { IconButton, Tooltip } from '@mui/material';
import { Archive } from '@mui/icons-material';

interface CustomPdfExportButtonProps {
  // TODO: Extend this to take a list of elements to allow multiple components to be included possibly over multiple pages
  rootElementId: string; // id of the component that is to be rendered in the pdf export
  width?: number;
  height?: number;
  title?: string;
}

const CustomPdfExportButton = (props: CustomPdfExportButtonProps) => {
  // get the root element that is to be rendered to a pdf from the props

  const { rootElementId } = props;

  const currentPage = useSelector(activePageSelector);
  const currentSection = useSelector(activeSectionSelector);
  const activeFund = useSelector(
    createActiveFundSelectorBySection(currentSection),
  );
  const clientName = useSelector(clientNameSelector) || 'mersenne';
  const configName = useSelector(configNameSelector) || 'mersenne_funds';
  const pageDetails = useSelector(
    createPageDetailsSelector(currentPage, currentSection),
  );
  const activeDate = useSelector(activeDateSelector);
  const WIDTH = 297;
  const HEIGHT = 210;

  const pdfExportHandler = async () => {
    const input = document.getElementById(rootElementId);
    // Convert the target component to html
    const imgData = await html2canvas(input as HTMLElement).then((canvas) => {
      return canvas.toDataURL('image/png');
    });
    const generator = new PdfGenerator({
      pageTitle: pageDetails?.pageTitle || 'Title',
      fundName: activeFund?.name || '',
      pageId: currentPage || 'pageId',
      fundId: activeFund?.id || '',
      clientName,
      configName,
      analysisDate:
        activeDate || formatDateForCheckingState(new Date()) || 'today',
    });
    generator.addPageTitlesAndImages();
    generator.addImage({
      src: imgData,
      width: WIDTH,
      height: HEIGHT - 8,
      alignment: 'center',
      proportionOfWidthOfPage: 1,
      specificYPosition: 8,
    });
    generator.save();
  };
  return (
    <div>
      <Tooltip title="Export page to PDF">
        <IconButton onClick={pdfExportHandler}>
          <Archive />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default CustomPdfExportButton;
