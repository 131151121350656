import { RouteObject } from 'react-router-dom';
import ValueAtRiskOverview from '../components/pages/ucits/var/ValueAtRiskOverview';
import ValueAtRiskNew from '../components/pages/sharedComponents/valueAtRiskNew/ValueAtRiskNew.component';
import UcitsLaw from '../components/pages/ucits/law/UcitsLaw';
import ExposureOverview from '../components/pages/sharedComponents/ExposureOverview';
import ProspectusRestrictionsOverview from '../components/pages/risk/prospectusRestrictions/ProspectusRestrictionsOverview.component';
import ExposureFund from '../components/pages/sharedComponents/ExposureFund';
import ProspectusRestrictions from '../components/pages/risk/prospectusRestrictions/ProspectusRestrictions.component';
import FailsPage, {
  FailsPageWithoutFund,
} from '../components/pages/utilities/Fails/FailsPage.component';
import ShareClassHedgeDataOverview from '../components/pages/reportsPages/shareClassHedge/ShareClassHedgeData.overview';
import ShareClassHedgeData from '../components/pages/reportsPages/shareClassHedge/ShareClassHedgeData.component';
import HistoricalDataExport from '../components/pages/manco/historicalDataExport/HistoricalDataExport.component';
import { checkFundIdLoader } from './Router';
import GeneralOverviewPage from '../components/layout/general/GeneralOverviewPage';
import UcitsLawOverviewPage from '../components/pages/ucits/law/UcitsLawOverview';
import { GeneralPageWrapper } from '../components/layout/general/GeneralPageWrapper';

// This is to handle the custom fund picker pages, a better solution might be available
export const CombinedRoutes = (data: any): RouteObject[] => [
  {
    path: '/:section/value-at-risk',
    element: (
      <GeneralPageWrapper pageId={'value-at-risk'}>
        <GeneralOverviewPage Component={ValueAtRiskOverview} />
      </GeneralPageWrapper>
    ),
    loader: (data) => checkFundIdLoader(data, 'value-at-risk', ValueAtRiskNew),
  },

  {
    path: '/:section/ucits-law',
    element: (
      <GeneralPageWrapper pageId={'ucits-law'}>
        <GeneralOverviewPage Component={UcitsLawOverviewPage} />
      </GeneralPageWrapper>
    ),
    loader: (data) => checkFundIdLoader(data, 'ucits-law', UcitsLaw),
  },
  {
    path: '/:section/exposure',
    element: (
      <GeneralPageWrapper pageId={'exposure'}>
        <GeneralOverviewPage Component={ExposureOverview} />
      </GeneralPageWrapper>
    ),
    loader: (data) => checkFundIdLoader(data, 'exposure', ExposureFund),
  },
  {
    path: '/:section/prospectus-restrictions',
    element: (
      <GeneralPageWrapper pageId={'prospectus-restrictions'}>
        <GeneralOverviewPage Component={ProspectusRestrictionsOverview} />
      </GeneralPageWrapper>
    ),
    loader: (data) =>
      checkFundIdLoader(
        data,
        'prospectus-restrictions',
        ProspectusRestrictions,
      ),
  },
  {
    path: '/:section/fails',
    element: (
      <GeneralPageWrapper pageId={'fails'}>
        <GeneralOverviewPage Component={FailsPageWithoutFund} />
      </GeneralPageWrapper>
    ),
    loader: (data) => checkFundIdLoader(data, 'fails', FailsPage),
  },

  {
    path: '/:section/share-class-hedge',
    element: (
      <GeneralPageWrapper pageId={'share-class-hedge'}>
        <GeneralOverviewPage Component={ShareClassHedgeDataOverview} />
      </GeneralPageWrapper>
    ),
    loader: (data) =>
      checkFundIdLoader(data, 'share-class-hedge', ShareClassHedgeData),
  },
  {
    path: '/:section/historical-data-export',
    element: (
      <GeneralPageWrapper pageId={'historical-data-export'}>
        <GeneralOverviewPage Component={HistoricalDataExport} />
      </GeneralPageWrapper>
    ),
    loader: (data) =>
      checkFundIdLoader(data, 'historical-data-export', HistoricalDataExport),
  },
];
