import React, { FC, ReactElement } from 'react';
import { DataObject } from '../../types/redux/data/dataTypes';
import RaptorLoading from '../feedback/RaptorLoading';
import DisplayAreaCenteredWrapper from '../layout/utilities/displayAreaWrapper';
import { ClientConfiguration } from '../../types/server-data/ClientConfig';
import ErrorBoundary from '../utilityComponents/ErrorBoundary';
import { activePageSelector } from '../../redux/pages/selectors';
import { useSelector } from 'react-redux';
import RaptorLogo from '../../images/RaptorLogo';
import NoDataMessage from '../feedback/NoDataMessage.component';
import { Box, Typography } from '@mui/material';

interface GeneralComponentErrorShieldProps {
  dataObjects: DataObject[] | ClientConfiguration;
  customErrorMessage?: string;
  customLoadingMessages?: string[];
  noMinHeightOnError?: boolean;
  setLoadingWidth?: number | string;
  setLoadingHeight?: number | string;
  children: React.ReactNode;
}

const renderErrorByCurrentPage = (
  props: GeneralComponentErrorShieldProps,
  errors: (Error | null)[] | Error | null,
  DEFAULT_ERROR: string,
  currentPage: string | null,
): ReactElement => {
  switch (currentPage) {
    case 'srri-share-class':
      return (
        <DisplayAreaCenteredWrapper noMinHeight={props.noMinHeightOnError}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '2rem',
            }}
          >
            <Box style={{ marginBottom: '5rem' }}>
              <RaptorLogo />
            </Box>
            <Typography variant="h1">
              You have not activated SRRI for this fund.
            </Typography>
            <Typography variant="h1">
              If you wish to do so, please contact the RiskSystem team.
            </Typography>
          </Box>
        </DisplayAreaCenteredWrapper>
      );
    default:
      return (
        <NoDataMessage
          message={
            Array.isArray(errors)
              ? props.customErrorMessage || errors[0]?.message || DEFAULT_ERROR
              : props.customErrorMessage || errors?.message || DEFAULT_ERROR
          }
        />
      );
  }
};

const GeneralComponentErrorShield: FC<GeneralComponentErrorShieldProps> = (
  props,
  children,
) => {
  let loading = false;
  let errors: (Error | null)[] | Error | null;
  let dataObjectsPresent = true;
  const currentPage = useSelector(activePageSelector);

  // check if we're dealing with the client configuration
  if ('configId' in props.dataObjects) {
    dataObjectsPresent = props.dataObjects !== null;
    loading = props.dataObjects.isFetching;
    errors = props.dataObjects.error;
  } else {
    dataObjectsPresent = props.dataObjects.reduce((prev: boolean, current) => {
      return prev && (current !== null || current !== undefined);
    }, true);
    loading = dataObjectsPresent
      ? props.dataObjects.reduce((prev: boolean, current) => {
          return prev || !current || current.isFetching;
        }, false)
      : true;
    errors = dataObjectsPresent
      ? props.dataObjects.reduce((prev: Error[], current: DataObject) => {
          if (!current) return [];
          if (current.error) {
            // Convert 404 error to more user friendly message
            if (current.error.message?.includes('status code 404')) {
              prev.push(new Error('The requested data could not be found.'));
            } else {
              prev.push(current.error);
            }
          }
          if (Array.isArray(current.data)) {
            if (
              typeof current.data[0] === 'object' &&
              'error' in current.data[0]
            ) {
              prev.push(new Error(current.data[0].error));
            } else if (
              typeof current.data[0] === 'string' &&
              current.data[0] === 'data not available'
            ) {
              prev.push(new Error('No data available.'));
            } else if (current.data[0] && 'no data' in current.data[0]) {
              prev.push(new Error('No data available for this date.'));
            }
          }

          return prev;
        }, [])
      : [];
  }

  const { noMinHeightOnError } = props;

  const DEFAULT_ERROR =
    'There was an error retrieving the data. The RiskSystem administrator has been notified.';
  return loading || !dataObjectsPresent ? (
    <RaptorLoading
      noMinHeightOnError={noMinHeightOnError}
      centerWrap
      messages={props.customLoadingMessages}
      setWidth={props.setLoadingWidth}
      setHeight={props.setLoadingHeight}
    />
  ) : (!Array.isArray(errors) && errors) || (errors && errors.length > 0) ? (
    renderErrorByCurrentPage(props, errors, DEFAULT_ERROR, currentPage)
  ) : (
    <ErrorBoundary>{props.children}</ErrorBoundary>
  );
};

export default GeneralComponentErrorShield;
