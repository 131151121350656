import React, { ReactElement, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import useFundNotes, { UseFundNoteProps } from '../../hooks/useFundNotes';
import useRouteParams from '../../hooks/useRouteParams';
import {
  clientNameSelector,
  configNameSelector,
} from '../../redux/auth/selectors';
import {
  activeDateSelector,
  activePageSelector,
  activeSectionSelector,
  allPagesSelector,
  createActiveFundSelectorBySection,
  createPageDetailsSelector,
} from '../../redux/pages/selectors';
import { componentsForExportSelector } from '../../redux/pdfExport/selectors';
import { CustomColumn } from '../../types/components/tables/tableTypes';
import { buildCsv } from '../../utilities/csvBuilder';
import { formatDateForCheckingState } from '../../utilities/dateFormatters';
import { PdfGenerator } from '../layout/titleBar/components/pdfExportButton/PdfExportButton';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Theme,
  Tooltip,
} from '@mui/material';
import { Archive } from '@mui/icons-material';
import { PDFConfig } from '../../types/components/charts/lineCharts';

const useStyles = makeStyles<Theme>((theme) => ({
  rootDiv: {
    marginRight: '.2rem',
  },
  root: {
    '& svg': {
      fontSize: '2rem',
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
  },
}));

interface AdditionalTableDetails {
  data: any[];
  columns: CustomColumn<any>[];
}

export type ColumnIdToHeaderMapType = { label: string; key: string }[];

interface Props {
  exportData: any[];
  pdfIdentifier?: string;
  fields: string[];
  fieldsMap?: ColumnIdToHeaderMapType;
  fileName?: string;
  allowPdfExport?: boolean;
  allowNoteExport?: UseFundNoteProps & AdditionalTableDetails;
  selectedPositionDate?: string;
  linkToRaptorExport?: boolean;
  pdfConfig?: PDFConfig;
}

const NoteExportSelection = ({
  fundId,
  subject,
  topic,
  positionDate,
  shouldCall,
  columns,
  data,
  prependWithDetail,
}: UseFundNoteProps & AdditionalTableDetails) => {
  const { notes, loading, fetchFundNotes, postFundNotes } = useFundNotes({
    fundId,
    subject,
    topic,
    positionDate,
    shouldCall,
    prependWithDetail,
  });
  const [open, setOpen] = useState(false);
  const handleNoteExport = async () => {
    const tableStringified = JSON.stringify({
      columns,
      data,
    });
    await postFundNotes('TABLE_NOTE:' + tableStringified);
    setOpen(true);
  };
  return (
    <>
      <MenuItem onClick={handleNoteExport} key="notes">
        Save to Note
      </MenuItem>
    </>
  );
};

// This needs a config prop to determine the pdf export

function ExportButton(props: React.PropsWithChildren<Props>): ReactElement {
  const navigate = useNavigate();
  const classes = useStyles();

  const componentsToExport = useSelector(componentsForExportSelector);

  //buildCsv just strips out what we don't want to keep
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const allPages = useSelector(allPagesSelector) || {};

  const currentPage = useSelector(activePageSelector);
  const currentSection = useSelector(activeSectionSelector);
  const activeFund = useSelector(
    createActiveFundSelectorBySection(currentSection),
  );
  const pageDetails = useSelector(
    createPageDetailsSelector(currentPage, currentSection),
  );
  const activeDate = useSelector(activeDateSelector);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const clientName = useSelector(clientNameSelector) || 'mersenne';
  const configName = useSelector(configNameSelector) || 'mersenne_funds';

  const handlePdfExport = () => {
    if (props.fields.length > 23) {
      alert(
        `Sorry, the maximum number of columns for pdf export is 23.\nYou have ${props.fields.length} columns selected.`,
      );
    } else {
      new PdfGenerator({
        idenftifier: props.pdfIdentifier || props?.pdfConfig?.pdfIdentifier,
        pageTitle: pageDetails?.pageTitle || 'Title',
        fundName: activeFund?.name || '',
        pageId: currentPage || 'pageId',
        fundId: activeFund?.id || '',
        clientName,
        configName,
        positionDate: props.selectedPositionDate,
        chartWidth: props?.pdfConfig?.chartWidth,
        analysisDate:
          activeDate || formatDateForCheckingState(new Date()) || 'today',
      }).generatePdf(componentsToExport);
    }
  };

  const csvData = buildCsv(props.fields, props.exportData);

  function goRaptorExport() {
    const raptorExportUrl = `/${currentSection}/raptor-export`;
    navigate(raptorExportUrl);
  }

  const generateCsvFilename = () => {
    if (props.fileName) {
      return props.fileName;
    } else {
      // Adepa in typical fashion has a different way of naming files
      // They always want the date to be the current date, not the active date
      // This is a hack to get around that
      // We should probably refactor this to be more flexible, ie have a client setting for allowing the client to choose whether to use the active date or the current date
      // Additionally, the entire export functionality should be refactored to be more flexible, allowing for more customization by client of file name etc
      if (clientName === 'adepa') {
        return `${currentPage}_${formatDateForCheckingState(new Date())}`;
      } else {
        return `${currentPage}_${useRouteParams('positionDate').positionDate || activeDate || formatDateForCheckingState(new Date())}`;
      }
    }
  };

  return (
    <>
      <Tooltip classes={{ tooltip: classes.tooltip }} title="Export">
        <IconButton classes={{ root: classes.root }} onClick={handleToggle}>
          <Archive />
        </IconButton>
      </Tooltip>
      <div ref={anchorRef}>
        <Popper
          style={{ zIndex: 20 }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    <CSVLink
                      data={csvData}
                      headers={props.fieldsMap}
                      target="_blank"
                      filename={generateCsvFilename()}
                    >
                      <MenuItem key={'csv'}>CSV</MenuItem>
                    </CSVLink>

                    {props.allowPdfExport && (
                      <MenuItem onClick={handlePdfExport} key="pdf">
                        PDF
                      </MenuItem>
                    )}
                    {props.allowNoteExport && (
                      <NoteExportSelection {...props.allowNoteExport} />
                    )}
                    {'raptor-export' in allPages &&
                      props.linkToRaptorExport && (
                        <MenuItem onClick={goRaptorExport} key="raptor-export">
                          Raptor Export
                        </MenuItem>
                      )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  );
}

export default ExportButton;
