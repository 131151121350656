import React, { FC, useEffect } from 'react';
import useFetchData from '../../../../hooks/useFetchData';
import LawFirstLevelTable from './lawComponents/LawFirstLevelTable';
import {
  formatFundUrl,
  makeUniqueDataKey,
} from '../../../../utilities/urlBuilder';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
  updateTitleBarSelectValue,
} from '../../../../redux/pages/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from '../../../../types/redux/pages/PagesStore';
import {
  APPEND_NEW_SELECT_TO_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
} from '../../../../types/redux/pages/pageTypes';
import { activePageSelector } from '../../../../redux/pages/selectors';
import GeneralComponentErrorShield from '../../../general/GeneralComponentErrorShield';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';

const FivgLaw: FC<FundInfoComponentProps> = ({
  fundId,
  fundName,
  positionDate,
}) => {
  const dispatch = useDispatch();
  const activePage = useSelector(activePageSelector);

  // [2024-11-22] - The selector for choosing historical data is temporarily removed for now until Lavy has time to implement it on backend.
  // // Add the select components to the title bar
  // useEffect(() => {
  //   const selectsToAdd: Select[] = [
  //     {
  //       titleBarTitle: 'Data Display: ',
  //       titleBarKey: 'dataDisplay',
  //       values: [
  //         {
  //           label: 'Current',
  //           value: 'current',
  //         },
  //         {
  //           label: 'Historical',
  //           value: 'historical',
  //         },
  //       ],
  //       displayOnOverviewPage: false,
  //       aValueIsAlwaysSelected: true,
  //       currentValue: 'current',
  //     },
  //   ];

  //   dispatch(
  //     addAdditionalTitleBarComponents(
  //       selectsToAdd,
  //       APPEND_NEW_SELECT_TO_TITLEBAR
  //     )
  //   );

  //   return () => {
  //     dispatch(removeTitleBarComponent('dataDisplay', REMOVE_SELECT_FROM_TITLEBAR));
  //   };

  // }, [activePage]);

  const fivgComplianceChecklist = useFetchData({
    url: 'compliance_checklist/FIVG',
    keyName: 'fivg_compliance_checklist',
    makeFetch: true,
  });

  const basicDataAgg = useFetchData({
    url: formatFundUrl('basic_data_agg', fundId, positionDate),
    keyName: makeUniqueDataKey(fundId, 'basicDataAgg', positionDate), // this is the same as var
    makeFetch: true,
  });

  const fivgFundData = useFetchData({
    url: formatFundUrl('fund_FIVG_data', fundId, positionDate),
    keyName: makeUniqueDataKey(fundId, 'fivgFundData', positionDate),
    makeFetch: true,
  });

  return (
    <GeneralComponentErrorShield
      dataObjects={[fivgComplianceChecklist, basicDataAgg, fivgFundData]}
      customLoadingMessages={[
        'Loading FIVG Law Data...',
        'Just a few more seconds...',
      ]}
      customErrorMessage="No data available for this date."
    >
      <LawFirstLevelTable
        dataSource={{
          complianceChecklist: fivgComplianceChecklist,
          basicDataAgg,
          fivgFundData,
        }}
        activeFundName={fundName}
        activeFund={fundId}
      />
    </GeneralComponentErrorShield>
  );
};

export default FivgLaw;
