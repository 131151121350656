import React, { FC, useEffect, useState } from 'react';
import { FundInfoComponentProps } from '../../../layout/general/GeneralFundInfoWrapper';
import useFetchData from '../../../../hooks/useFetchData';
import { formatDateForCheckingState } from '../../../../utilities/dateFormatters';
import GridItem from '../../../layout/GridComponents/GridItem';
import RaptorLoading from '../../../feedback/RaptorLoading';
import ReportsTable from './table/component';
import { AifmdReportType } from '../overview/table/components/ReportsLinkButton/reportsLinkButton.component';
import { formatFundUrl } from '../../../../utilities/urlBuilder';
import { useLocation } from 'react-router-dom';
import useTitleBarSelect from '../../../../hooks/useTitleBarSelect';
import { useDispatch } from 'react-redux';
import {
  addAdditionalTitleBarComponents,
  removeTitleBarComponent,
  updateTitleBarSelectValue,
} from '../../../../redux/pages/actions';
import { Select } from '../../../../types/redux/pages/PagesStore';
import {
  APPEND_NEW_SELECT_TO_TITLEBAR,
  REMOVE_SELECT_FROM_TITLEBAR,
} from '../../../../types/redux/pages/pageTypes';

export const REPORTS_OPTIONS = {
  market: 'Market Limit Monitor',
  counterparty: 'Counterparty Limit Monitor',
  credit: 'Credit Limit Monitor',
  liquidity: 'Liquidity Limit Monitor',
};

//
const AifmdReports: FC<FundInfoComponentProps> = ({ fundId, positionDate }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  // Add the select components to the title bar
  useEffect(() => {
    const selectsToAdd: Select[] = [
      {
        titleBarTitle: 'Report Type: ',
        titleBarKey: 'reportType',
        values: Object.keys(REPORTS_OPTIONS).map((key) => ({
          label: REPORTS_OPTIONS[key as keyof typeof REPORTS_OPTIONS],
          value: key,
        })),
        displayOnOverviewPage: false,
        aValueIsAlwaysSelected: true,
        currentValue: 'market',
      },
    ];

    dispatch(
      addAdditionalTitleBarComponents(
        selectsToAdd,
        APPEND_NEW_SELECT_TO_TITLEBAR,
      ),
    );

    return () => {
      dispatch(
        removeTitleBarComponent('reportType', REMOVE_SELECT_FROM_TITLEBAR),
      );
    };
  }, []);

  const selectedReport = useTitleBarSelect('reportType');
  const [localStorageReportValue, setLocalStorageReportValue] = useState<
    string | null
  >(null);

  useEffect(() => {
    const reportType = localStorage.getItem('AIF_REPORT_TYPE');
    if (reportType) {
      setLocalStorageReportValue(reportType);
    }
  }, [location.state]);

  useEffect(() => {
    if (selectedReport && localStorageReportValue) {
      dispatch(
        updateTitleBarSelectValue('reportType', localStorageReportValue),
      );
      setLocalStorageReportValue(null);
    }
  }, [selectedReport]);

  const reportsData = useFetchData({
    keyName: `aifmd_reports_${
      positionDate || formatDateForCheckingState(new Date())
    }_${fundId}`,
    url: formatFundUrl('aifmd_aggregate_risk_report', fundId, positionDate),
    makeFetch: true,
  });

  return reportsData ? (
    !reportsData.isFetching ? (
      <GridItem card xs={12}>
        <ReportsTable
          inputData={reportsData}
          reportType={
            (selectedReport as unknown as AifmdReportType) || 'market'
          }
        />
      </GridItem>
    ) : (
      <RaptorLoading centerWrap />
    )
  ) : null;
};

export default AifmdReports;
