import { makeStyles } from '@mui/styles';
import { greys, mainColors } from '../../../../../styling/theme';

export const useRaidrViewStyles = makeStyles(() => ({
  parentContainer: {
    minHeight: '20rem',
    display: 'flex',
    flexDirection: 'column',
    margin: '2rem',
    gap: '2rem',
    width: '100%',
  },
  buttonsContainer: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
  },
  button: {
    height: '3rem',
    marginRight: '1rem',
    width: '10rem',
  },
  viewerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '1rem',
  },
  iframeContainer: {
    border: '2px solid',
    borderColor: mainColors.mainBlue,
    width: '100%',
    height: '85vh',
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: 'white',
  },
  arrow: {
    '&:before': {
      border: '1px solid #8e8e8e',
    },
    color: greys.grey600,
  },
  tooltip: {
    backgroundColor: greys.grey600,
    border: '1px solid #8e8e8e',
    color: 'white',
  },
  fundNameContainer: {
    width: '100%',
    maxHeight: '3rem',
    marginRight: '1rem',
    marginLeft: '1rem',
    whiteSpace: 'nowrap',
  },
  versionNumberContainer: {
    width: '100%',
    maxHeight: '3rem',
    marginRight: '1rem',
    marginLeft: '1rem',
    whiteSpace: 'nowrap',
    backgroundColor: mainColors.mainBlue,
    borderRadius: '5px',
    color: 'white',
    boxSizing: 'border-box',
    paddingRight: '5px',
    paddingLeft: '5px',
    fontSize: '1.2rem',
    display: 'flex',
    fontWeight: 600,
  },
}));
