import { ReactElement, useState } from 'react';

import { mainColors } from '../../styling/theme';
import GeneralSelect from './GeneralSelect';
import { BASE_URL } from '../../utilities/requestClient';
import { MenuItem, Typography } from '@mui/material';

interface Props {
  reports: string[];
  fundName: string;
  providedStringPrefix?: string;
}

export interface CustomMenuItemProps {
  label: string;
  value: string;
  fundName?: string | null;
}

function ReportsSelect({
  reports,
  fundName,
  providedStringPrefix,
}: Props): ReactElement {
  const [selected, setSelected] = useState('none_selected');

  const urlPrefix = providedStringPrefix || 'ucits_historical_report';
  function ReportsSelectMenuItem(props: CustomMenuItemProps): ReactElement {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={BASE_URL + urlPrefix + '/' + props.label + '/' + props.fundName}
        style={{ textDecoration: 'none', color: mainColors.mainBlue }}
      >
        <MenuItem value={props.value} style={{ color: mainColors.mainBlue }}>
          {props.label
            .replace('risksystem_daily_risk_report_', '')
            .replace('risksystem_daily_liquidity_risk_report_', '') // Add this in for the liquidity overview.
            .replace('.pdf', '')
            .replace(/_/g, '-')}
        </MenuItem>
      </a>
    );
  }
  return reports && reports.length ? (
    <GeneralSelect
      selected={selected}
      setSelected={setSelected}
      labels={reports}
      values={reports}
      placeholderValue="Select a report"
      customMenuItem={ReportsSelectMenuItem}
      disableUnderline={true}
      placeholderColor={mainColors.mainBlue}
      fundName={fundName}
    />
  ) : (
    <Typography
      style={{ fontSize: '1.4rem', color: mainColors.mainBlue }}
      variant="h5"
    >
      No reports available
    </Typography>
  );
}

export default ReportsSelect;
