import React from 'react';
import { greys, mainColors } from '../../../styling/theme';
import RaptorLogo from '../../../images/RaptorLogo';
import NavContainer from './NavContainer.component';
import LogoRender from '../sidebar/LogoRender/LogoRender.component';
import UserOptionsMenu from './UserOptionsMenu.component';
import { useSelector } from 'react-redux';
import { clientNameSelector, configNameSelector } from '../../../redux/auth/selectors';
import { useNavigate, useLocation } from 'react-router';
import { zIndexes } from '../../../styling/zIndexes';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowBackIos } from '@mui/icons-material';

const useStyles = makeStyles({
  topBarRoot: {
    height: '6rem',
    width: '100vw',
    padding: '1rem 2rem 1rem 0.5rem',
    backgroundColor: 'white',
    borderBottom: `1px solid ${greys.grey100}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: zIndexes.topBar,
  },
  raptorLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 1rem',
    cursor: 'pointer',
  },
  userItems: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  topBarContents: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 1rem',
  },
  topBarContentsBackButton: {
    fontSize: '2rem',
    fontWeight: 600,
    color: mainColors.mainBlue,
    padding: '0.5rem 1rem',
    borderRadius: '0.4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    '& svg': {
      fontSize: '2rem',
    },
    '&:hover': {
      backgroundColor: greys.grey100,
    },
  },
});

const TopBar: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const clientName = useSelector(clientNameSelector);
  const configName = useSelector(configNameSelector);

  const { pathname } = useLocation();
  const [_, section] = pathname.split('/');

  const redirectDashboard = (e: React.MouseEvent) => {
    navigate(`/dashboard`);
  };

  return (
    <>
      <div className={classes.topBarRoot}>
        <div className={classes.raptorLogo} onClick={redirectDashboard}>
          <RaptorLogo />
        </div>
        {section === 'documentation' ? (
          <div className={classes.topBarContents}>
            <div
              className={classes.topBarContentsBackButton}
              onClick={redirectDashboard}
            >
              <ArrowBackIos /> Dashboard
            </div>
          </div>
        ) : (
          <NavContainer />
        )}
        <div className={classes.userItems}>
          <>
            <LogoRender
              clientName={(clientName as string) || 'mersenne'}
              configName={(configName as string) || 'mersenne_funds'}
            />
            <UserOptionsMenu />
          </>
        </div>
      </div>
    </>
  );
};

export default React.memo(TopBar);
