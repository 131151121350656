import TopBar from '../topBar/TopBar';
import DocumentationMenu from '../documentationMenu/DocumentationMenu.component';
import RaptorSnackbar from '../../feedback/raptorSnackbar/Snackbar.component';
import { mainColors } from '../../../styling/theme';
import makeStyles from '@mui/styles/makeStyles';
import { Outlet } from 'react-router-dom';
import AuthenticatedPage from '../../../routes/utils/AuthenticatedPage';

const useStyles = makeStyles({
  body: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: mainColors.background,
  },
  main: {
    height: 'calc(100% - 6rem)',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    padding: '1rem',
  },
});

const DocumentationLayout = () => {
  const classes = useStyles();

  return (
    <div className={classes.body}>
      <TopBar />
      <main className={classes.main}>
        <DocumentationMenu />
        <div className={classes.content}>
          <AuthenticatedPage Component={Outlet} hardSection="documenation" />
        </div>
      </main>
      <RaptorSnackbar />
    </div>
  );
};
export default DocumentationLayout;
