import { useMutation } from '@tanstack/react-query';
import { generateNewKID, generateNewReviewKID } from './api.routes';

export function useGenerateNewKIDPdf() {
  return useMutation({
    mutationFn: (data: FormData) => generateNewKID(data),
    // onMutate: () => {
    //   console.log('mutate');
    // },

    // onError: () => {
    //   console.log('error');
    // },

    // onSuccess: () => {
    //   console.log('success');
    // },

    // onSettled: async (_, error, variables) => {
    //   console.log('settled');
    // },
  });
}

export function useGenerateNewReviewKIDPdf() {
  return useMutation({
    mutationFn: (data: FormData) => generateNewReviewKID(data),
    // onMutate: () => {
    //   console.log('mutate');
    // },

    // onError: () => {
    //   console.log('error');
    // },

    // onSuccess: () => {
    //   console.log('success');
    // },

    // onSettled: async (_, error, variables) => {
    //   console.log('settled');
    // },
  });
}
