import React, { FC } from 'react';
import { EditorTileProps } from './PriipsKidsEditorColumn.component';
import {
  ContentState,
  DraftEditorCommand,
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import PriipsKidsEditorGridItem from '../../layout/PriipsKidsEditorGridItem.component';
import StyleFormatButtons from '../../../components/buttons/StyleFormatButtons.component';
import makeStyles from '@mui/styles/makeStyles';
import PriipsKidsFieldTypeSelect from '../../buttons/PriipsKidsFieldTypeSelect.component';
import { PriipsKidsData, Tag } from '../KidsEditor';
import UtilityButtons from '../../../components/buttons/UtilityButtons.component';
import {
  handleEditorKeyCommand,
  sanitiseEditorText,
} from '../../utils/editor.utils';

const useStyles = makeStyles(() => ({
  textEditorBox: {
    border: '1px solid',
    borderColor: 'black',
    marginTop: '2rem',
    backgroundColor: 'white',
    marginBottom: '2rem',
    marginLeft: '1rem',
    fontSize: '1.5rem',
    boxSizing: 'border-box',
    padding: '1rem',
    borderRadius: '6px',
    width: '100%',
    minHeight: '10rem',
  },
  editorContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginRight: '1rem',
  },
  formatContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: '1rem',
  },
}));

const TextEditorTile: FC<EditorTileProps> = ({
  priipsKidsElement,
  setPriipsKidsContent,
  updateFieldType,
  moveField,
  deleteField,
}) => {
  const { kiidIndex: index, content, fieldId, tag } = priipsKidsElement;

  // Create an editor state object that will store the current text and allow it to be edited
  // Convert the conteny to html
  const blocksFromHTML = convertFromHTML(content);
  // Now create cintent state object from the html
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
  );
  // Store the initial state
  const initialState = EditorState.createWithContent(contentState);
  // Create a state object to store the editor state
  const [editorState, setEditorState] =
    React.useState<EditorState>(initialState);

  const handleTextChange = (updatedEditorState: EditorState) => {
    // Update data
    setEditorState(updatedEditorState);

    const originalText = stateToHTML(updatedEditorState.getCurrentContent());

    // Sanitise the text from the editor to fit the expected format for the PDF generator
    const sanitisedText = sanitiseEditorText(originalText);

    // Update the overall data
    setPriipsKidsContent((allContent: PriipsKidsData[]) => {
      const newContent = [...allContent];
      newContent[index].content = sanitisedText;
      return newContent;
    });
  };

  // Function for handling keyboard shortcuts for styling
  const handleKeyCommand = (command: DraftEditorCommand) => {
    return handleEditorKeyCommand(command, editorState, setEditorState);
  };

  // Create a function for handling key commands
  const handleToggleClick = (e: React.MouseEvent, inlineStyle: string) => {
    e.preventDefault();
    // Apply the desired inline style to the highlighted text
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    // Get the updated state as plain text
    let text = stateToHTML(editorState.getCurrentContent());
    // Format some of the html tags to fit in with the pdf generator expected format
    text = text
      .replaceAll('<p>', '')
      .replaceAll('</p>', '')
      .replaceAll('<strong>', '<b>')
      .replaceAll('</strong>', '</b>')
      .replaceAll('<em>', '<i>')
      .replaceAll('</em>', '</i>');
    // Update the overall data
    setPriipsKidsContent((allContent: PriipsKidsData[]) => {
      const newContent = [...allContent];
      newContent[index].content = text;
      return newContent;
    });
  };

  const classes = useStyles();

  // List of tags which can have a custom font size
  const tagsWhichCanHaveCustomFontSize: Tag[] = [
    'text_full',
    'text_col',
  ];

  // Parse formatOptions and get fontSize
  const getCustomFontSize = (): number | null | Error => {
    try {
      if (priipsKidsElement.formatOptions) {
        const formatOptions = JSON.parse(priipsKidsElement.formatOptions);
        return formatOptions.font_size || null;
      }
    } catch (e) {
      // If JSON parsing fails, raise an error
      console.error('Invalid formatOptions JSON, please contact support:', priipsKidsElement.formatOptions);
      return new Error('Invalid format options, please contact support');
    }
    return null;
  };

  // Function for getting the default font size, based on the tag of the tile
  // Eventually we should get this from the backend to have a single source of truth
  const getDefaultFontSize = (): number => {
    switch (priipsKidsElement.tag) {
      case 'text_full':
        return 10;
      case 'text_col':
        return 10;
      case 'section_header':
        return 12;
      default:
        return 10;
    }
  };

  // State for whether the tile has a custom font size
  const [hasCustomFontSize, setHasCustomFontSize] = React.useState<boolean | Error>(
    getCustomFontSize() !== null
  );

  // Function for toggling the custom font size
  const handleToggleCustomFontSize = () => {
    // Update the overall data
    setPriipsKidsContent((allContent: PriipsKidsData[]) => {
      const newContent = [...allContent];
      const currentFormatOptions = newContent[index].formatOptions ? JSON.parse(newContent[index].formatOptions) : {};
      if (hasCustomFontSize) {
        // Remove font_size if we currently have custom font size
        // If there are no other options then set the formatOptions to an empty string (this is to avoid the formatOptions being set to '{}', and registering as a change)
        const { font_size, ...restOptions } = currentFormatOptions;
        newContent[index].formatOptions = Object.keys(restOptions).length === 0 ? '' : JSON.stringify(restOptions);
      } else {
        // Add font_size if we don't currently have custom font size
        newContent[index].formatOptions = JSON.stringify({
          ...currentFormatOptions,
          font_size: getDefaultFontSize(),
        });
      }
      return newContent;
    });
    setHasCustomFontSize(!hasCustomFontSize);
  };

  // Function for changing the custom font size
  const handleFontSizeChange = (newSize: number) => {
    // Update the overall data
    setPriipsKidsContent((allContent: PriipsKidsData[]) => {
      const newContent = [...allContent];
      const currentFormatOptions = newContent[index].formatOptions ? JSON.parse(newContent[index].formatOptions) : {};
      newContent[index].formatOptions = JSON.stringify({
        ...currentFormatOptions,
        font_size: newSize,
      });
      return newContent;
    });
  };

  return (
    <PriipsKidsEditorGridItem xs={12}>
      <div className={classes.formatContainer}>
        <StyleFormatButtons
          handleToggleClick={handleToggleClick}
          fontSizeConfig={tagsWhichCanHaveCustomFontSize.includes(priipsKidsElement.tag) ? {
            hasCustomFontSize: hasCustomFontSize,
            onToggleCustomFontSize: handleToggleCustomFontSize,
            currentSize: getCustomFontSize() || getDefaultFontSize(),
            onSizeChange: handleFontSizeChange,
          } : undefined}
        />
        <PriipsKidsFieldTypeSelect
          initialType={tag}
          updateFieldType={updateFieldType}
          index={index}
        />
      </div>
      <div className={classes.editorContainer}>
        <div className={classes.textEditorBox}>
          <Editor
            editorState={editorState}
            onChange={handleTextChange}
            stripPastedStyles={true}
            handleKeyCommand={handleKeyCommand}
          />
        </div>
        <UtilityButtons
          moveField={moveField}
          index={index}
          deleteField={deleteField}
          fieldId={fieldId}
        />
      </div>
    </PriipsKidsEditorGridItem>
  );
};

export default TextEditorTile;
