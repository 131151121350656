import { Forbidden } from '../components/settings/pages/Forbidden';
import PageNotFound from '../components/settings/pages/PageNotFound.component';
import { Section } from '../types/server-data/ClientConfig';
import { All_RAPTOR_ROUTES } from '../routes/Router';

/**
 * The `withAuthorization` HOC applies authorization logic based on the provided `section` and `pageId`.
 * If the user has access, it renders the `Component` with the additional `props` passed to it.
 *
 * @param {React.ComponentType<any>} Component - The rendered component on pass.
 * @param {string} section - The section
 * @param {string} pageId - The page
 * @param {Section[] | null} clientSections - The client sections
 *
 * @returns {React.ReactElement} - Returns the authorized component with `props` bound, or redirects if unauthorized.
 */

const withAuthorization = (
  Component: React.ComponentType,
  section: string,
  pageId: string,
  clientSections: Section[] | null,
) => {
  const clientAllowedSection = clientSections?.find(
    (mappedSection) => mappedSection.sectionId === section,
  );

  const hasAccess = clientAllowedSection?.pages?.includes(pageId);

  if (!All_RAPTOR_ROUTES().includes(pageId)) {
    return <PageNotFound />;
  }

  return hasAccess ? <Component /> : <Forbidden />;
};

export default withAuthorization;
