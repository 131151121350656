import React, { FC } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  activeDateSelector,
  availableDatesSelector,
} from '../../../redux/pages/selectors';

type GeneralOverviewPageProps = {
  Component: React.ComponentType<any>;
};

const GeneralOverviewPage: FC<GeneralOverviewPageProps> = ({
  Component,
}) => {
  const { section } = useParams<any>();
  const loaderData = useLoaderData() as any;

  const activeDate = useSelector(activeDateSelector);
  const availableDates = useSelector(availableDatesSelector);

  // Check if loaderData includes a child component to render
  const childComponent = loaderData?.props?.children;

  if (!childComponent) {
    return (
      <Component
        section={section || ''}
        positionDate={activeDate || undefined}
        availableDates={availableDates || undefined}
      />
    );
  }

  // Extract and render the child component dynamically
  const { props, type } = childComponent;

  const RenderedChild = type; // Get the component type from loaderData
  return (
    <RenderedChild
      {...props}
      section={section || ''}
      positionDate={activeDate || undefined}
      availableDates={availableDates || undefined}
    />
  );
};

export default GeneralOverviewPage;
