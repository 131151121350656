import React, { ReactNode, useEffect } from 'react';
import './styling/base.css';
import makeStyles from '@mui/styles/makeStyles';
import { RouterProvider } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from './styling/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';
import {
  ConfirmationProvider,
  ConfirmationWrapper,
} from './components/context/ConfirmationContext';
import { AxiosError } from 'axios';
import { router } from './routes/Router';

export const queryClient = new QueryClient();

// Here we can declare as we will never not use our axios instances for crud requests
declare module '@tanstack/react-query' {
  interface Register {
    defaultError: AxiosError;
  }
}

const useStyles = makeStyles(() => ({
  app: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
  },
}));

const EnsureBasename = ({
  children,
  basename,
}: {
  children: JSX.Element;
  basename: string;
}) => {
  const location = window.location;

  useEffect(() => {
    if (!location.pathname.startsWith(basename)) {
      const fullPath = `${basename}${location.pathname}`;
      window.location.replace(fullPath); // Redirect to correct path
    }
  }, [basename, location.pathname]);

  return children;
};

const App: React.FC = () => {
  const classes = useStyles();

  return (
    <QueryClientProvider client={queryClient}>
      <DndProvider backend={HTML5Backend}>
        <ConfirmationProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <div className={classes.app}>
                <EnsureBasename basename="/raptor">
                  <RouterProvider router={router('')} />
                </EnsureBasename>
                <ConfirmationWrapper />
              </div>
              <Toaster position="bottom-right" />
            </ThemeProvider>
          </StyledEngineProvider>
        </ConfirmationProvider>
      </DndProvider>
    </QueryClientProvider>
  );
};

export default App;
