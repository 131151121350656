import { makeStyles } from '@mui/styles';
import { mainColors } from '../../../../../styling/theme';

export const useKidsEditorStyles = makeStyles(() => ({
  editorPageParent: {
    display: 'flex',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    gap: '5rem',
    justifyContent: 'center',
    height: '100%',
  },
  editorContainer: {
    maxWidth: '80rem',
    width: '100%',
    padding: '0 1rem',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.5rem',
    },
    '&::-webkit-scrollbar-track': {
      border: '0.2rem solid white',
      backgroundColor: mainColors.lightGrey,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: mainColors.mainBlue,
      borderRadius: '1rem',
    },
  },
  viewerContainer: {
    width: '100%',
  },
}));
